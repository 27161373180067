import React from 'react';
import MainPage from './views/main-page/main-page';
import Landingpage from './views/landingpage/landingpage';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ component: Component, ...rest }) {
  const token = localStorage.getItem('token');
  return (
    {
      ...rest.path === "/" ?
        <Route exact path="/" component={token ? MainPage : Landingpage} />
        :
        <Route
          {...rest}
          render={props => (token ? <Component {...props} /> : <Redirect to="/" />)}
        />
    }

  );
}

export default PrivateRoute;
