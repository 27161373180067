import React, { Component } from 'react';

import Header from '../../components/header/header';
import './detail-page.scss';
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Image,
  Spinner
} from 'react-bootstrap';

import ModalComponent from '../../components/modal/modal';
import { Whatsapp, Facebook } from 'react-social-sharing';
import Bounce from 'react-reveal/Bounce';

import Views from '@material-ui/icons/VisibilityOutlined';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import BackButton from '../../components/back-button/back-button';
import SpamButton from '../../components/spam-button/spam-button';
import { fetchDetails, deleteJob, postViewCounter } from '../../utils/fetch';
class DetailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReported: false,
      details: {},
      isDeleted: false,
      isLoading: false,
      openContact: false,
      ownEntry: false,
      counter: 0
    };
  }

  componentDidMount() {
    this.getDetails();
    this.setViewCounter();
  }

  render() {
    const {
      isReported,
      details,
      isDeleted,
      isLoading,
      openContact,

      ownEntry,
      counter
    } = this.state;
    const id = this.props.match.params.id;
    return (
      <div className="detail-page">
        <Header />
        <BackButton />
        {ownEntry ? (
          <div className="delete-button">
            <Button
              variant="danger"
              onClick={() => this.setState({ isDeleted: true })}
            >
              Löschen
            </Button>
          </div>
        ) : (
          <SpamButton
            showSpamModal={() =>
              this.setState({ isOpen: !this.state.isOpen, isReported: true })
            }
          />
        )}

        <ModalComponent
          title="Melden"
          text="Bist du sicher, dass du diese Anzeigen melden möchtest?"
          open={isReported}
          onClose={() => this.handleReport()}
          type="report"
        />
        <ModalComponent
          title="Löschen"
          text="Möchtest Du diesen Eintrag wirklich löschen?"
          open={isDeleted}
          onClose={() => this.setState({ isDeleted: !this.state.isDeleted })}
          onDelete={() => this.deleteEntry()}
          type="delete"
        />
        <ModalComponent
          title="Kontakt aufnehmen"
          open={openContact}
          onClose={() =>
            this.setState({ openContact: !this.state.openContact })
          }
          type="contact"
        />

        <Container className="detail-page-container">
          <Row>
            <Col>
              {!isLoading ? (
                <Bounce left>
                  <Card>
                    <Card.Header>
                      <Card.Title>{details && details['title']}</Card.Title>
                      <div className="view-counter">
                        <div>
                          <Views />
                        </div>
                        <div>
                          <p>{counter}</p>
                        </div>
                      </div>
                      <Card.Subtitle>
                        <div className="image-container">
                          {details && details['type'] === 0 ? (
                            <Image
                              alt="suche"
                              src="../../../images/icons/help_active.png"
                            />
                          ) : (
                            <Image
                              alt="biete"
                              src="../../../images/icons/give_active.png"
                            />
                          )}
                        </div>
                      </Card.Subtitle>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>{details && details['description']}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <div className="card-text-with-icon">
                        <ChatIcon />
                        <div className="contact-link">
                          <div
                            onClick={() =>
                              this.props.history.push('/message', {
                                state: { details: details }
                              })
                            }
                          >
                            Nimm Kontakt mit{' '}
                            <b>{details && details.username}</b> auf
                          </div>
                        </div>
                      </div>
                    </Card.Footer>
                  </Card>
                  <div className="share-text">
                    <div>Teile diese Anzeige mit deinen Freunden!</div>
                  </div>
                  <div className="social-media">
                    <div classname="share-wa">
                      <Whatsapp
                        link={`https://www.thefriendnextdoor.de/details/${id}`}
                      />{' '}
                    </div>
                    <div className="share-fb">
                      <Facebook
                        link={`https://www.thefriendnextdoor.de/details/${id}`}
                      />
                    </div>
                  </div>
                </Bounce>
              ) : (
                <div className="loading-spinner">
                  <Spinner animation="grow" variant="info" />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  handleReport = () => {
    this.setState({ isReported: !this.state.isReported });
  };

  deleteEntry = async () => {
    const token = localStorage.getItem('token');
    const id = this.props.match.params.id;
    this.setState({ isDeleted: true });
    try {
      await deleteJob(token, id).then(res => {
        if (res.status === 200) {
          this.props.history.push('/');
          this.setState({ isLoading: false });
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  getDetails = async () => {
    const token = localStorage.getItem('token');
    const id = this.props.match.params.id;
    this.setState({ isLoading: true });
    try {
      await fetchDetails(token, id).then(res => {
        if (res.status === 200) {
          res.json().then(data => {
            this.setState({
              details: data,
              isLoading: false,
              ownEntry: data.self,
              counter: data.showed,
              userID: data.accID,
              type: data.type
            });
          });
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  setViewCounter = async () => {
    const token = localStorage.getItem('token');
    const id = this.props.match.params.id;
    try {
      await postViewCounter(token, id).then(res => {
        res.json();
      });
    } catch (e) {
      console.error(e);
    }
  };
}

export default DetailPage;
