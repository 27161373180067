import React, { Component, createRef } from 'react';
import { fetchLogin } from '../../utils/fetch';
import Header from '../../components/header/header';
import {
  Form,
  Card,
  Button,
  Container,
  Row,
  Col,
  Modal,
  Spinner
} from 'react-bootstrap';
import Footer from '../../components/footer/footer';
import { postPasswordRest } from '../../utils/fetch';
import { Link } from 'react-router-dom';
import Flip from 'react-reveal/Flip';
import './login.scss';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      errorModal: false,
      isLoading: false,
      errors: {
        username: '',
        password: '',
        email: ''
      },
      initialValidation: false,
      invalidForm: false,
      email: '',
      closed: false
    };

    this.passwordRef = createRef();
    this.usernameRef = createRef();
  }

  render() {
    const {
      invalidForm,
      isLoading,
      errors,

      closed
    } = this.state;

    return (
      <div className="login">
        <Header />
        <>
          <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={closed}
            onHide={() => this.handleClose()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Passwort vergessen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formTitel">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    required
                    onChange={event => this.handleChange(event)}
                    formNoValidate
                    onFocus={() => this.setState({ invalidForm: false })}
                    onKeyDown={event =>
                      event.key === 'Enter' && this.handleSubmit(event)
                    }
                  />
                  <span className="error">{errors.email}</span>
                  <Form.Text className="text-muted">
                    Beispiel: maxmustermann@muster.de
                  </Form.Text>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <div>
                {invalidForm && (
                  <div className="invalid-form">Eingabe überprüfen.</div>
                )}
              </div>
              <Button
                className="primary"
                onClick={event => this.handlePWSubmit(event)}
              >
                Passwort zurücksetzen
              </Button>
            </Modal.Footer>
          </Modal>
        </>
        {!isLoading ? (
          <>
            <Container>
              <Row>
                <Col lg={4} md={5} sm={7}>
                  <Flip left>
                    <Card>
                      <Card.Header>
                        <Card.Title>Login</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Form.Group>
                          <Form.Label>Benutzername</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            onChange={event => this.handleChange(event)}
                            onFocus={() => this.setState({ invalidForm: false })}
                            name="username"
                            formNoValidate
                            onKeyDown={event =>
                              event.key === 'Enter' && this.handleSubmit(event)
                            }
                          />
                          <span className="error">{errors.username}</span>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            required
                            onChange={event => this.handleChange(event)}
                            onFocus={() => this.setState({ invalidForm: false })}
                            name="password"
                            formNoValidate
                            onKeyDown={event =>
                              event.key === 'Enter' && this.handleSubmit(event)
                            }
                          />
                          <span className="error">{errors.password}</span>
                        </Form.Group>
                        <div>
                          {invalidForm && (
                            <div className="invalid-form">
                              Benutzername oder Passwort falsch.
                            </div>
                          )}
                        </div>
                        <Button variant="primary" block onClick={this.handleSubmit}>
                          Login
                        </Button>
                      </Card.Body>
                      <div className="login-register">
                        <Link to="/signup">Noch keinen Account?</Link>
                      </div>
                      <div className="lost-credentials">
                        <div
                          className="lost-password"
                          onClick={() =>
                            this.setState({
                              closed: !this.state.closed
                            })
                          }
                          ref={this.passwordRef}
                        >
                          Password vergessen?
                      </div>
                      </div>
                    </Card>
                  </Flip>
                </Col>
              </Row>
            </Container>
            <Footer />
          </>
        ) : (
            <div className="loading-spinner">
              <Spinner animation="grow" variant="info" />
            </div>
          )}
      </div>
    );
  }

  handleReset = async () => {
    const { email } = this.state;
    try {
      await postPasswordRest(email).then(res => {
        if (res.status === 200) {
          res.json().then(data => {
            this.setState({ closed: !this.state.closed });
          });
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleClose = () => {
    this.setState({ closed: !this.state.closed });
  };

  handleChange = event => {
    event.preventDefault();
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const { name, value } = event.target;
    const { errors } = this.state;

    switch (name) {
      case 'username':
        errors.username =
          value.length <= 0 ? 'Das Feld darf nicht leer sein.' : '';
        break;

      case 'password':
        errors.password =
          value.length <= 0 ? 'Das Feld darf nicht leer sein.' : '';
        break;

      case 'email':
        errors.email = validEmailRegex.test(value)
          ? ''
          : 'Diese Email hat das falsche Format.';

      default:
        break;
    }
    this.setState({
      errors,
      [name]: value,
      initialValidation: true,
      invalidForm: false
    });
  };

  handlePWSubmit = event => {
    const { errors, username, password } = this.state;
    event.preventDefault();

    if (this.validatePWForm(errors)) {
      this.handleReset(username, password);
    } else {
      console.error('Invalid Form');
      return;
    }
  };

  handleSubmit = event => {
    const { errors, username, password } = this.state;
    event.preventDefault();

    if (this.validateForm(errors)) {
      this.login(username, password);
    } else {
      console.error('Invalid Form');
      return;
    }
  };

  validatePWForm = error => {
    const { initialValidation, email } = this.state;
    let valid = true;
    if (!initialValidation || !email) {
      valid = false;
      this.setState({ invalidForm: true });
      return;
    }

    Object.values(error).forEach(val => val.length > 0 && (valid = false));
    this.setState({ invalidForm: false });
    return valid;
  };

  validateForm = error => {
    const { initialValidation, username, password } = this.state;
    let valid = true;
    if (!initialValidation || !username || !password) {
      valid = false;
      this.setState({ invalidForm: true });
      return;
    }

    Object.values(error).forEach(val => val.length > 0 && (valid = false));
    this.setState({ invalidForm: false });
    return valid;
  };

  login = async (username, password) => {
    this.setState({ isLoading: true });
    try {
      await fetchLogin(username, password).then(response => {
        if (response.status === 200) {
          response.json().then(async data => {
            if (data.username) {
              const token = data.token;
              localStorage.setItem('token', token);
              localStorage.setItem('username', data.username);
              this.props.history.push('/home');
              this.setState({ isLoading: false });
            }
          });
        } else {
          this.setState({ invalidForm: true, isLoading: false });
        }
      });
    } catch (err) {
      console.log(`ERROR: ${err}`);
    }
  };
}

export default Login;
