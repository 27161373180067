import React, { useState } from 'react';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import './rangeSlider.scss';

const RSlider = ({ handleRSliderChange }) => {
  const [value, setValue] = useState(25);

  const setRSValue = value => {
    setValue(value);
    handleRSliderChange(value);
    localStorage.setItem('rangeValue', value);
  };
  const rangeValue = localStorage.getItem('rangeValue');

  return (
    <div className="RangeSlider">
      <RangeSlider
        value={Number(rangeValue ? rangeValue : value)}
        onChange={changeEvent => setRSValue(changeEvent.target.value)}
        min={1}
        max={50}
        size="lg"
        tooltip="on"
        tooltipPlacement="top"
        tooltipLabel={currentValue =>
          `Radius: ${rangeValue ? rangeValue : currentValue}km`
        }
      />
    </div>
  );
};

export default RSlider;
