import React from 'react';
import { Card, Image } from 'react-bootstrap';
import './list-item.scss';

function ListItem(props) {
  return (
    <div className="list-item" key={props.index}>
      <Card>
        <Card.Header>
          <Card.Title>{props.title}</Card.Title>

          <Card.Subtitle>
            <div className="distance-container">
              {props.distance && props.distance > 1 ? (
                <p>{props.distance} km</p>
              ) : (
                props.distance <= 1 && <p>sehr nah</p>
              )}
            </div>

            <div className="image-container">
              {props.type === 0 ? (
                <Image
                  alt="suche"
                  src="../../../images/icons/help_active.png"
                />
              ) : (
                <Image
                  alt="biete"
                  src="../../../images/icons/give_active.png"
                />
              )}
            </div>
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          <Card.Text>{props.description}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ListItem;
