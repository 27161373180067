import React from 'react';
import { Button } from 'react-bootstrap';
import BackButton from '../../components/back-button/back-button';
import Header from '../../components/header/header';
import Bounce from 'react-reveal/Bounce';
import './informations.scss';

function Informations(props) {
  return (
    <div className="informations">
      <BackButton />
      <Header />
      <Bounce left>
        <div className="button-container">
          <div className="button">
            <Button
              className="primary"
              onClick={() => props.history.push('/impressum')}
            >
              Impressum
            </Button>
          </div>
          <div
            className="button"
            onClick={() => props.history.push('/data-privacies')}
          >
            <Button className="primary">Datenschutz</Button>
          </div>
          <div
            className="button"
            onClick={() => props.history.push('/privacies')}
          >
            <Button className="primary">Nutzungsbedingungen</Button>
          </div>
        </div>
      </Bounce>
    </div>
  );
}

export default Informations;
