import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import './accordion-item.scss';

function AccordionItem(props) {
  return (
    <div className="accordion-container">
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          variant="link"
          eventKey={props.eventKey}
        >
          {props.accordionTitle}
        </Accordion.Toggle>

        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body
            dangerouslySetInnerHTML={{ __html: `${props.accordionText}` }}
          />
        </Accordion.Collapse>
      </Card>
    </div>
  );
}

export default AccordionItem;
