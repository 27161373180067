import React from 'react';
import { Button } from 'react-bootstrap';
import { isInStandaloneMode, isIos } from '../../utils/misc';
import './spam-button.scss';

function SpamButton(props) {
  return (
    <div
      className={`spam-button ${isInStandaloneMode() && isIos() && 'isIos'}`}
    >
      <Button variant="warning" onClick={props.showSpamModal}>
        Melden
      </Button>
    </div>
  );
}

export default SpamButton;
