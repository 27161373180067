export const faqs = [
  {
    id: 0,
    title: 'Für wen und warum wurde TheFriendNextDoor entwickelt?',
    text:
      'TheFriendNextDoor wurde gegründet, um allen Menschen eine weitere Möglichkeit zu geben, Nachbarn und Freunden in dieser schweren Zeit unkompliziert zu helfen. Ohne grosse Anmeldeprozeduren kann jeder sofort Hilfe anbieten oder um Hilfe jeder Art bitten.'
  },
  {
    id: 1,
    title: 'Ist TheFriendNextDoor kostenlos?',
    text: 'TheFriendNextDoor ist völlig kostenlos'
  },
  {
    id: 2,
    title: 'Wie funktioniert TheFriendNextDoor?',
    text:
      'Mit TheFriendNextDoor kann man automatisch sehen, wer in der Nähe genau zu den eigenen Bedürfnissen passt und kann diese Person sofort kontaktieren. Vom Handy aus ist die Genauigkeit so hoch, dass auch eine gezielte Suche nach Hilfebedürftigen - z.B. beim Spazierengehen oder auf dem Weg zum Einkaufen - möglich ist. Unternehmen und Organisationen jeder Art (z.B. Restaurants, Kirchen, Lebensmittelgeschäfte etc.) haben bei TheFriendNextDoor die Gelegenheit, gezielt Personen in ihrem Umkreis über einen Lieferservice und andere passende Dienste zu informieren. Einfach die Adresse <a href="http://www.thefriendnextdoor.de">http://www.TheFriendNextDoor.de</a> als Startadresse eingeben und schon kann es losgehen.'
  },
  {
    id: 3,
    title:
      'Muss ich mich bei TheFriendNextDoor anmelden, um alle Funktionen nutzen zu können?',
    text:
      'Melden Sie sich kostenlos an, um alle Funktionen nutzen zu können. Einfach die Adresse <a href="http://www.TheFriendNextDoor.de">http://www.TheFriendNextDoor.de</a> als Startadresse eingeben und schon kann es losgehen. Geben Sie hierzu eine auf Sie laufende und gültige Emailadresse ein. Sie erhalten später eine Email, in der Sie die Registrierung bestätigen müssen, um den Anmeldevorgang abzuschliessen.'
  },
  {
    id: 4,
    title: 'Wer kann TheFriendNextDoor benutzen?',
    text:
      'Jeder kann TheFriendNextDoor nutzen. Ob Privatpersonen oder Unternehmen, egal ob in Deutschland oder im Ausland. Bitte beachten Sie die Nutzungsbedingungen und Datenschutzbestimmungen.'
  },
  {
    id: 5,
    title: 'Was ist alles bei TheFriendNextDoor erlaubt?',
    text:
      'Durch Ihr Einverständnis garantieren Sie uns, dass Sie keine Beiträge erstellen werden, die gegen die Nutzungsbedingung verstoßen. Entsprechendes gilt auch für das Setzen von externen Links.'
  },
  {
    id: 6,
    title:
      'Was bietet TheFriendNextDoor speziell für Unternehmen und Organisationen an?',
    text:
      'Nicht nur Privatpersonen, sondern auch Unternehmen und Organisationen können TheFriendNextDoor nutzen, um ihre Angebote oder Gesuche bekannt zu machen. z.B. können Restaurants und Einzelhandelsgeschäfte bekannt machen, dass sie einen Abhol- oder Lieferservice anbieten.'
  },
  {
    id: 7,
    title:
      'Anmeldung bei TheFriendNextDoor ist momentan nicht möglich, was kann ich machen?',
    text:
      'Aufgrund hoher Nachfrage kann es in seltenen Fällen leider zu einer Überlastung des Systems kommen. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.'
  },
  {
    id: 8,
    title: 'Mit welchen Geräten funktioniert TheFriendNextDoor?',
    text:
      'TheFriendNextDoor funktioniert auf jedem Handy, Smartphone, iPad, PDA, PC, Laptop, Notepad und sogar auf Navigationsgeräten im Auto, die über einen Browser verfügen. Einfach die Adresse <a href="http://www.TheFriendNextDoor.de">http://www.TheFriendNextDoor.de</a> im Internetbrowser als Startadresse eingeben und schon kann es losgehen.'
  },
  {
    id: 9,
    title: 'Gibt es TheFriendNextDoor auch als App?',
    text:
      'Momentan kann man TheFriendNextDoor direkt mit jedem Internetbrowser erreichen und benutzen. Es ist keine separate Installation einer App notwendig.'
  },
  {
    id: 10,
    title:
      'Wie kann ich TheFriendNextDoor permanent auf meinem Handy speichern - so wie eine App?',
    text:
      'Beim erstmaligen Aufrufen von TheFriendNextDoor wird die Möglichkeit angeboten, die Startseite permanent auf dem Handy zu speichern. Klickt man dann in Zukunft auf das TheFriendNextDoor-Icon, wird man automatisch auf die Startseite gebracht.'
  },
  {
    id: 11,
    title:
      'Wie kann ich zu einem anderen User von TheFriendNextDoor Kontakt aufnehmen, um zu helfen?',
    text:
      'Jeder User hat sich vor Benutzung von TheFriendNextDoor angemeldet und Emailadresse und Telefonnummer angegeben, sodass man bei Interesse direkt Kontakt mit der entsprechenden Person aufnehmen kann.'
  },
  {
    id: 12,
    title: 'Wo kann ich den problematischen Eintrag eines Users melden?',
    text:
      'Benutzen Sie den Melde-Button, um uns direkt einen auffäligen Eintrag zu melden'
  },
  {
    id: 13,
    title: 'Wie kann ich ein Angebot bei TheFriendNextDoor eintragen?',
    text:
      'Nach Registrierung können Sie über ein Formular Gesuche oder Angebote eingeben. Drücken Sie auf das grosse Plus-Zeichen unten rechts, um einen neuen Eintrag vorzunehmen. Klicken Sie zunächst auf den „Ich biete“-Button. Danach geben Sie Ihrem Angebot einen passenden Titel, der aussagekräftig sein sollte, damit man sofort versteht, worum es geht. Anschliessend haben Sie viel Platz, um Ihr Angebot näher zu beschreiben. Bitte geben Sie schliesslich Ihre Email-Adresse und Ihre Telefonnummer ein, damit man Sie so schnell wie möglich finden kann, um Ihnen zu helfen oder damit Ihre Hilfe schnell in Anspruch genommen werden kann. Bedenken Sie, dass danach Ihre Telefonnummer und Email-Adresse öffentlich sichtbar ist.'
  },
  {
    id: 14,
    title: 'Wie kann ich ein Gesuch bei TheFriendNextDoor eintragen?',
    text:
      'Nach Registrierung können Sie über ein Formular Gesuche oder Angebote eingeben. Drücken Sie auf das grosse Plus-Zeichen unten rechts, um einen neuen Eintrag vorzunehmen. Klicken Sie zunächst auf den „Ich suche“-Button. Danach geben Sie Ihrem Gesuch einen passenden Titel, der aussagekräftig sein sollte, damit man sofort versteht, worum es geht. Anschliessend haben Sie viel Platz, um Ihr Angebot näher zu beschreiben. Bitte geben Sie schliesslich Ihre Email-Adresse und Ihre Telefonnummer ein, damit man Sie so schnell wie möglich finden kann, um Ihnen zu helfen oder damit Ihre Hilfe schnell in Anspruch genommen werden kann. Bedenken Sie, dass danach Ihre Telefonnummer und Email-Adresse öffentlich sichtbar ist.'
  },
  {
    id: 15,
    title: 'Wie kann ich mit dem TheFriendNextDoor-Team in Kontakt treten?',
    text:
      'Jeder User hat sich vor Benutzung von TheFriendNextDoor angemeldet und Emailadresse und Telefonnummer angegeben, sodass man bei Interesse direkt Kontakt mit der entsprechenden Person, Unternehmen oder Organisation aufnehmen kann.'
  },
  {
    id: 16,
    title:
      'Ich möchte bei TheFriendNextDoor mitmachen, wo kann ich mich melden?',
    text:
      'Bitte benutzen Sie einfach unser Kontaktformular, um mit uns in Kontakt zu treten. Beschreiben Sie so genau wie möglich, wie Sie uns helfen können.'
  },
  {
    id: 17,
    title:
      'Ich möchte TheFriendNextDoor auch in andere Länder bringen, wie kann ich dabei helfen?',
    text:
      'Bitte benutzen Sie einfach unser Kontaktformular und teilen Sie uns mit, um welches Land es dabei geht. Wir freuen uns auf jede Art von Hilfe. Gemeinsam können wir mehr bewegen. Wir arbeiten momentan bereits an Versionen für andere Länder, die schon sehr bald zur Verfügung stehen werden.'
  },
  {
    id: 18,
    title:
      'Ich habe einen Fehler in der Software oder im Text gefunden, wo kann ich den melden?',
    text:
      'Bitte benutzen Sie unser allgemeines Formular, um uns einen Fehler zu melden. Wir werden ihn so schnell wie möglich beheben.'
  },
  {
    id: 19,
    title:
      'Ich habe eine neue Idee für TheFriendNextDoor - wie kann ich diese dem Entwicklerteam mitteilen?',
    text:
      'Benutzen Sie das allgemeine Formular, um uns Ihre Ideen mitzuteilen. Wir arbeiten derzeit an vielen neuen Funktionen, die demnächst automatisch in unsere Software einfliessen werden.'
  },
  {
    id: 20,
    title:
      'Auf meinem Handy funktioniert TheFriendNextDoor nicht, was kann ich machen?',
    text:
      'Prüfen Sie zuerst, ob eine Internetverbindung besteht. Falls es weiterhin Probleme gibt, schliessen Sie den Internetbrowser und starten Sie ihn erneut. Achten Sie darauf, dass Sie die Internetadresse richtig eingeben: <a href="http://www.TheFriendNextDoor.de">http://www.TheFriendNextDoor.de</a>'
  },
  {
    id: 21,
    title:
      'Bei meinem Handy funktioniert die GPS-Angabe von TheFriendNextDoor nicht, was soll ich machen?',
    text:
      'Prüfen Sie, ob Ihre Handy-Einstellungen die Benutzung von GPS überhaupt zulässt. Schalten Sie,  falls nötig, die GPS-Benutzung für Ihren Internetbrowser frei'
  }
];

export const host =
  process.env.REACT_APP_ENV === 'development'
    ? 'https://api-dev.thefriendnextdoor.de/'
    : 'https://api.thefriendnextdoor.de/';

export const isInStandaloneMode = () =>
  'standalone' in window.navigator && window.navigator.standalone;
export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};
