import React, { Component } from 'react';
import {
  Form,
  Button,
  Card,
  Container,
  Col,
  Row,
  Spinner
} from 'react-bootstrap';
import Header from '../../components/header/header';
import BackButton from '../../components/back-button/back-button';
import ModalComponent from '../../components/modal/modal';
import Bounce from 'react-reveal/Bounce';
import './message.scss';
import { postMessage } from '../../utils/fetch';

class Message extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      errors: {
        message: ''
      },
      initialValidation: false,
      invalidForm: false,
      isLoading: false,
      isSent: false
    };
  }

  render() {
    const { errors, invalidForm, isSent, isLoading } = this.state;
    const { state } = this.props.location.state;
    return (
      <div className="message">
        <Header />
        <BackButton />
        <ModalComponent
          title="Nachricht verschickt."
          text="Deine Nachricht wurde erfolgreich übermittelt. Schaue regelmäßig in dein E-Mail Postfach, um zu sehen, ob du eine Nachricht von dem Ersteller erhalten hast."
          open={isSent}
          onSend={() => this.handleSend()}
          type="message"
        />
        <Container>
          <Col>
            {!isLoading ? (
              <Bounce left>
                <Row>
                  <Card>
                    <Card.Header>
                      <Card.Title>
                        Nachricht zur Anzeige "{state.details.title}"
                      </Card.Title>
                      <Card.Subtitle className="text-muted">
                        Nehme Kontakt mit dem Anzeigenersteller auf.
                      </Card.Subtitle>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                        <Form.Group controlId="formTitel">
                          <Form.Label>Nachricht</Form.Label>
                          <Form.Control
                            as="textarea"
                            type="text"
                            name="message"
                            required
                            onChange={event => {
                              this.handleChange(event);
                            }}
                            formNoValidate
                          />
                          <Form.Text className="text-muted">
                            Hinterlasse auch alternative
                            Kommunikationsmöglichkeiten, wie zum Beispiel eine
                            Handynummer.
                          </Form.Text>

                          <span className="error">{errors.message}</span>
                        </Form.Group>
                      </Form>
                    </Card.Body>
                    <Card.Footer>
                      <div>
                        {invalidForm && (
                          <div className="invalid-form">
                            Bitte gib eine Nachricht ein.
                          </div>
                        )}
                        <Button
                          varian="primary"
                          onClick={event => this.handleSubmit(event)}
                        >
                          Abschicken
                        </Button>
                      </div>
                    </Card.Footer>
                  </Card>
                </Row>
              </Bounce>
            ) : (
              <div className="loading-spinner">
                <Spinner animation="grow" variant="info" />
              </div>
            )}
          </Col>
        </Container>
      </div>
    );
  }

  handleSend = () => {
    this.setState({ isSent: !this.state.isSent });
    this.props.history.push('/');
  };

  handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    const { errors } = this.state;

    switch (name) {
      case 'message':
        errors.message =
          value.length === 0 ? 'Bitte gib eine Nachricht ein.' : '';
        break;

      default:
        break;
    }
    this.setState({
      errors,
      [name]: value,
      initialValidation: true,
      invalidForm: false
    });
  };

  handleSubmit = event => {
    const { errors, message } = this.state;
    const token = localStorage.getItem('token');
    const { state } = this.props.location.state;

    event.preventDefault();

    if (this.validateForm(errors)) {
      this.sendMessage(
        token,
        state.details.accID,
        state.details.username,
        message,
        state.details.title,
        state.details.type
      );
    } else {
      console.error('Invalid Form');
      return;
    }
  };

  validateForm = error => {
    const { initialValidation, message } = this.state;
    let valid = true;
    if (!initialValidation || !message) {
      valid = false;
      this.setState({ invalidForm: true });
      return;
    }

    Object.values(error).forEach(val => val.length > 0 && (valid = false));
    this.setState({ invalidForm: false });
    return valid;
  };

  sendMessage = async (token, userID, message, title, type) => {
    this.setState({ isLoading: true });
    try {
      await postMessage(token, userID, message, title, type).then(res => {
        if (res.status === 200) {
          this.setState({ isLoading: false, isSent: true });
        }
      });
    } catch (e) {
      console.error(e);
    }
  };
}

export default Message;
