import React, { Component } from 'react';
import './logout.scss';

class Logout extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return <h1>Logout</h1>;
  }
}

export default Logout;
