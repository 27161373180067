import React, { useState, useEffect } from 'react';
import './typeswitcher.scss';

const Typeswitcher = ({ handleTypeChange }) => {
  const [optionState, setOptionState] = useState(0);

  useEffect(() => {
    handleTypeChange(optionState);
  }, [optionState]);

  const setGive = () => {
    setOptionState(1);
  };

  const setHelp = () => {
    setOptionState(0);
  };

  return (
    <div className="typeswitcher">
      <div className="option" onClick={setHelp}>
        {optionState === 0 ? (
          <span className="icon help_active" />
        ) : (
          <span className="icon help_inactive" />
        )}
        <p>Ich suche</p>
      </div>
      <div className="option" onClick={setGive}>
        {optionState === 1 ? (
          <span className="icon give_active" />
        ) : (
          <span className="icon give_inactive" />
        )}
        <p>Ich biete</p>
      </div>
    </div>
  );
};

export default Typeswitcher;
