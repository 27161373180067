import React, { Component } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import Map from '../map/map';
import './locationPermissionModal.scss';

class locationPermissionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      permission: null,
      closed: false,
      isSafari: false,
      coords: {
        latitude: 50,
        longitude: 8,
        accuracy: 0
      }
    };
  }

  componentDidMount() {
    this.checkBrowser();
    this.checkPermission();
  }

  checkBrowser = () => {
    const ua = navigator.userAgent.toLowerCase();
    console.log(ua);
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('edge') > -1) {
        this.setState({ isSafari: true });
        console.log('edge'); // Edge
      } else if (ua.indexOf('chrome') > -1) {
        console.log('chrome'); // Chrome
      } else {
        console.log('safari'); // Safari
        this.setState({ isSafari: true });
      }
    }
  };

  handleClose = () => this.setState({ showModal: false });

  getLocation = async () => {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };

    const success = position => {
      const coords = {
        longitude: position.coords.longitude,
        latitude: position.coords.latitude,
        accuracy: position.coords.accuracy
      };

      this.setState({ permission: true });

      this.setState({
        coords
      });
    };

    const error = err => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      this.setState({ permission: false });
    };

    await navigator.geolocation.getCurrentPosition(success, error, options);
  };

  askPermission = () => {
    this.getLocation();
  };

  checkPermission = async () => {
    const coords = await localStorage.getItem('location');
    if (!this.state.isSafari) {
      await navigator.permissions
        .query({ name: 'geolocation' })
        .then(async result => {
          if (result.state === 'granted') {
            this.getLocation();
            if (!coords) this.setState({ showModal: true });
          } else if (result.state === 'prompt') {
            this.setState({ showModal: true });
          } else {
            if (!coords) this.setState({ showModal: true });
          }
        });
    } else if (!coords) this.setState({ showModal: true });
  };

  acceptPosition = () => {
    this.handleClose();
    localStorage.setItem('location', JSON.stringify(this.state.coords));
  };

  toggle = () => {
    this.setState({ closed: !this.state.closed });
  };

  changeLocationManual = newCoords => {
    this.setState({ coords: newCoords });
  };

  render() {
    const { permission, coords, showModal } = this.state;
    return (
      <>
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModal}
          onHide={() => this.toggle()}
        >
          <Modal.Header>
            <Modal.Title>Hilf in deiner direkten Umgebung!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {permission == null ? (
              <div className="col s12 infotext">
                <p>
                  Wir nutzen deine GPS daten um dir Leute in deiner direkten
                  Umgebung anzeigen zu können.
                </p>
              </div>
            ) : permission && coords.latitude !== 0 && coords.accuracy < 500 ? (
              <div>
                Ist das deine aktuelle Position?
                <Map
                  coords={coords}
                  changeLocation={this.changeLocationManual}
                />
              </div>
            ) : permission === false ? (
              <div>
                Leider stellt dein Gerät keine Position zur verfügung. Per
                Klick kannst du deine Position festlegen. Nutze auch die Zoom
                funktion.
                <Map
                  variant="manual"
                  coords={coords}
                  changeLocation={this.changeLocationManual}
                />
              </div>
            ) : (
              permission === true &&
              (coords.accuracy > 500 || !coords.accuracy) && (
                <div>
                  <b>Leider ist die Ermittlung deiner Position zu ungenau.</b> Bitte
                  wähle deinen Standort manuell aus.
                  <Map
                    variant="manual"
                    coords={coords}
                    changeLocation={this.changeLocationManual}
                  />
                </div>
              )
            )}
          </Modal.Body>
          <Modal.Footer>
            {permission === null ? (
              <Button onClick={this.askPermission}>Alles Klar</Button>
            ) : permission === false ? (
              <Button variant="primary" onClick={this.acceptPosition}>
                Bestätigen
              </Button>
            ) : permission && coords.latitude !== 0 ? (
              <>
                <Button variant="secondary" onClick={this.getLocation}>
                  Aktualisieren
                </Button>
                <Button variant="primary" onClick={this.acceptPosition}>
                  Korrekt
                </Button>
              </>
            ) : permission === true && coords.accuracy >= 1000 ? (
              <Button variant="primary" onClick={this.acceptPosition}>
                Bestätigen
              </Button>
            ) : (
              <div className="loading-spinner">
                <Spinner animation="grow" variant="info" />
              </div>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default locationPermissionModal;
