import React, { Component } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Header from '../../components/header/header';
import './general-informations.scss';

function GeneralInformations() {
  return (
    <div className="general-informations">
      <Header />
      <Container>
        <Row>
          <Col className="general-informations-headline">
            <h2 style={{ paddingBottom: '20px' }}>Corona Informationen</h2>
            <p>
              Hier finden Sie allgemeine Informationen zu Corona - Covid19 aus
              verschiedenen Bereichen. Es stellt nur einen Teil der verfügbaren
              Informationen dar und soll Ihnen helfen, Einstieg in diese
              Thematik zu finden. Haben Sie noch weitere informative Seiten zu
              Themen rund um Corona- und COVID-19 gefunden? Dann schreiben Sie
              uns
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>Coronavirus Symptome Anzeichen</Card.Title>
                <Card.Subtitle></Card.Subtitle>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Der ärztliche Bereitschaftsdienst - Patientenservice{' '}
                  <a href="tel:116117">116117</a>
                  <br />
                  <a href="https://www.116117.de/de/coronavirus.php">
                    https://www.116117.de/de/coronavirus.php
                  </a>
                  <br />
                  <br />
                  Zuständiges Gesundheitamt in Ihrer Region finden (Postleitzahl
                  ist einzugeben)
                  <br />
                  <a href="https://tools.rki.de/PLZTool/">
                    https://tools.rki.de/PLZTool/
                  </a>
                  <br />
                  <br />
                  Gesundheitsamt Hochtaunuskreis - Kreisausschuss
                  <br />
                  Hochtaunuskreis Fachbereich Gesundheitsdienste
                  <br />
                  Ludwig-Erhard-Anlage 1-4 61348
                  <br /> Bad Homburg Telefon:{' '}
                  <a href="tel:06172-9990">06172-9990</a>
                  <br />
                  Email:{' '}
                  <a href="mailto:gesundheitsamt@hochtaunuskreis.de">
                    gesundheitsamt@hochtaunuskreis.de
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>Corona Covid-19 aktuelle News</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Aktuelle Zahlen zu COVID-19 weltweit nach einzelnen Ländern
                  This interactive dashboard/map provides the latest global
                  numbers and numbers by country of COVID-19 cases on a daily
                  basis.
                  <br />
                  <br />
                  <a href="https://experience.arcgis.com/experience/685d0ace521648f8a5beeeee1b9125cd">
                    https://experience.arcgis.com/experience/
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>
                  COVID-19 und Corona Newsservices direkt auf Ihr Handy oder PC
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Ab sofort informier das Bundesgesundheitsministerium auch auf
                  WhatsApp über das Coronavirus. Um den Infokanal zu nutzen,
                  speichern Sie die Nummer +49 151 62875183 und schreiben
                  "Start" oder klicken Sie mobil hier:
                  https://t.co/62Tk4qc6sU?amp=1 WHO's Health Alert on WhatsApp
                  auf Arabisch, Spanisch, Französisch, Englisch und Italienisch
                  <br />
                  <br />
                  <a href="https://www.who.int/news-room/feature-stories/detail/who-health-alert-brings-covid-19-facts-to-billions-via-whatsapp">
                    https://www.who.int/news-room/feature-stories/detail/who-health-alert-brings-covid-19-facts-to-billions-via-whatsappd
                  </a>
                  <br />
                  <br />
                  Arabic Send "مرحبا" to{' '}
                  <a href="tel:+41 22 501 70 23">+41 22 501 70 23</a> on
                  WhatsApp wa.me/41225017023?text=مرحبا <br />
                  <br />
                  English Send "hi" to{' '}
                  <a href="tel:+41 79 893 18 92">+41 79 893 18 92</a> on
                  WhatsApp wa.me/41798931892?text=hi
                  <br />
                  <br /> French Send "salut" to{' '}
                  <a href="tel:+41 22 501 72 98">+41 22 501 72 98</a> on
                  WhatsApp wa.me/41225017298?text=salut
                  <br />
                  <br /> Italian Send "ciao" to{' '}
                  <a href="tel:+41 22 501 78 34">+41 22 501 78 34</a> on
                  WhatsApp https://wa.me/41225017834?text=ciao
                  <br />
                  <br />
                  Spanish Send "hola" to{' '}
                  <a href="tel:+41 22 501 76 90">+41 22 501 76 90</a> on
                  WhatsApp wa.me/41225017690?text=hola
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>Corona Covid 19 Vorsorgeinformationen</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Bundeszentrale für gesundheitliche Aufklärung (BZgA)
                  <a href="https://www.infektionsschutz.de/coronavirus/">
                    https://www.infektionsschutz.de/coronavirus/
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>Corona Frangen und Antworten FAQ</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  WHO is continuously monitoring and responding to this
                  outbreak:
                  <br />
                  <a href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses">
                    https://www.who.int/news-room/q-a-detail/q-a-coronaviruses
                  </a>
                  <br />
                  <br />
                  Bundesministerium für Ernährung und Landwirtschaft
                  <br />
                  <a href="https://www.bmel.de/DE/Ministerium/_Texte/corona-virus-faq-fragen-antworten.html">
                    https://www.bmel.de/DE/Ministerium/_Texte/corona-virus-faq-fragen-antworten.html
                  </a>
                  <br />
                  <br />
                  Fragen zum Umgang mit Corona der Arzt-Praxis
                  <br />
                  <a href="https://www.kvhessen.de/coronavirus/faq/">
                    https://www.kvhessen.de/coronavirus/faq/
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>Finanzhilfe für Corona Betroffene</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Übersicht über Soforthilfen:
                  <br />
                  <a href="https://www.bundesfinanzministerium.de/Content/DE/Pressemitteilungen/Finanzpolitik/2020/03/2020-03-29-PM-Verwaltungsvereinbarung-Soforthilfe-Anlage.pdf;jsessionid=1BF9C324A1848B55E51120094E4CB3EE.delivery2-master?__blob=publicationFile&v=7">
                    https://www.bundesfinanzministerium.de/Content/DE/Pressemitteilungen/Finanzpolitik/2020/03/2020-03-29-PM-Verwaltungsvereinbarung-Soforthilfe-Anlage.pdf;jsessionid=1BF9C324A1848B55E51120094E4CB3EE.delivery2-master?__blob=publicationFile&v=7
                  </a>
                  <br />
                  <br />
                  Corona Virus - Informationen und Unterstützung für Unternehmen
                  des Bundeswirtschaftsministeriums
                  <br />
                  <a href="https://www.bmwi.de/Redaktion/DE/Coronavirus/coronahilfe.html">
                    https://www.bmwi.de/Redaktion/DE/Coronavirus/coronahilfe.html
                  </a>
                  <br />
                  <br />
                  Arbeitsrechtliche Infomationen aufgrund Corona Pandemie
                  <br />
                  <a href="https://www.bmas.de/DE/Schwerpunkte/Informationen-Corona/informationen-corona.html">
                    https://www.bmas.de/DE/Schwerpunkte/Informationen-Corona/informationen-corona.html
                  </a>
                  <br />
                  <br />
                  Informationen zu Corona Hilfen in Hamburg durch Hamburgische
                  Investitions- und Förderbank <br />
                  <a href="https://www.ifbhh.de/magazin/news/coronavirus-hilfen-fuer-unternehmen">
                    https://www.ifbhh.de/magazin/news/coronavirus-hilfen-fuer-unternehmen
                  </a>
                  <br />
                  <br />
                  Corona Hilfe Baden-Württemberg
                  <br />
                  <a href="https://wm.baden-wuerttemberg.de/soforthilfe-corona">
                    https://wm.baden-wuerttemberg.de/soforthilfe-corona
                  </a>
                  <br />
                  <br />
                  Hilfe für Unternehmen in Thüringen
                  <br />
                  <a href="https://aufbaubank.de/Foerderprogramme/Soforthilfe-Corona-2020">
                    https://aufbaubank.de/Foerderprogramme/Soforthilfe-Corona-2020
                  </a>
                  <br />
                  <br />
                  Finanzhilfe für Unternehmen in Niedersachsen
                  <br />
                  <a href="https://www.nbank.de/Blickpunkt/Covid-19-%E2%80%93-Beratung-f%C3%BCr-unsere-Kunden.jsp">
                    https://www.nbank.de/Blickpunkt/Covid-19-%E2%80%93-Beratung-f%C3%BCr-unsere-Kunden.jsp
                  </a>
                  <br />
                  <br />
                  Corona Hilfe Bayern
                  <br />
                  <a href="https://www.stmwi.bayern.de/soforthilfe-corona/">
                    https://www.stmwi.bayern.de/soforthilfe-corona/
                  </a>
                  <br />
                  <br />
                  Informationen zur finanzielle Unterstützung in Bremen
                  <br />
                  <a href="https://www.bab-bremen.de/bab/corona-soforthilfe.html">
                    https://www.bab-bremen.de/bab/corona-soforthilfe.html
                  </a>
                  <br />
                  <br />
                  Infos zu Corona Finanzhilfen in Nordrhein-Westfalen
                  <br />
                  <a href="https://wirtschaft.nrw/corona">
                    https://wirtschaft.nrw/corona
                  </a>
                  <br />
                  <br />
                  Soforthilfeinfos in Schleswig Holstein
                  <br />
                  <a href="https://www.ib-sh.de/infoseite/corona-beratung-fuer-unternehmen/">
                    https://www.ib-sh.de/infoseite/corona-beratung-fuer-unternehmen/
                  </a>
                  <br />
                  <br />
                  Covid-19 Soforthilfe Infos Sachsen
                  <br />
                  <a href="https://www.sab.sachsen.de/">
                    https://www.sab.sachsen.de/
                  </a>
                  <br />
                  <br />
                  Landesförderinstitut MecklenburgVorpommern <br />
                  <a href="https://www.lfi-mv.de/foerderungen/corona-soforthilfe">
                    https://www.lfi-mv.de/foerderungen/corona-soforthilfe
                  </a>
                  <br />
                  <br />
                  Informationen zur finanzielle Unterstützung in Rheinland Pfalz
                  <br />
                  <a href="https://isb.rlp.de/home.html">
                    https://isb.rlp.de/home.html
                  </a>
                  <br />
                  <br />
                  Soforthilfe Finanzhilfe in Hessen:
                  <br />
                  <a href="https://wirtschaft.hessen.de/wirtschaft/corona-info/soforthilfe-fuer-selbststaendige-freiberufler-und-kleine-betriebe">
                    https://wirtschaft.hessen.de/wirtschaft/corona-info/soforthilfe-fuer-selbststaendige-freiberufler-und-kleine-betriebe
                  </a>
                  <br />
                  <br />
                  Soforthilfe Sachsen
                  <br />
                  <a href="https://www.ib-sachsen-anhalt.de/coronavirus-informationen-fuer-unternehmen">
                    https://www.ib-sachsen-anhalt.de/coronavirus-informationen-fuer-unternehmen
                  </a>
                  <br />
                  <br />
                  Beratung und Soforthilfe wegen Corona in Berlin
                  <br />
                  <a href="https://www.ibb.de/coronahilfen">
                    https://www.ibb.de/coronahilfen
                  </a>
                  <br />
                  <br />
                  COVID-19 Hilfen in Brandenburg
                  <br />
                  <a href="https://www.ilb.de/de/wirtschaft/zuschuesse/soforthilfe-corona-brandenburg/">
                    https://www.ilb.de/de/wirtschaft/zuschuesse/soforthilfe-corona-brandenburg/
                  </a>
                  <br />
                  <br />
                  Soforthilfe Informationen des Saarlandes
                  <br />
                  <a href="http://www.corona.wirtschaft.saarland.de/">
                    http://www.corona.wirtschaft.saarland.de/
                  </a>
                  <br />
                  <br />
                  Ihre Rechte wenn Versanstaltungen abgesagt werden
                  <br />
                  <a href="https://www.verbraucherzentrale.de/wissen/vertraege-reklamation/kundenrechte/wenn-veranstaltungen-wegen-corona-abgesagt-werden-ihre-rechte-45416">
                    https://www.verbraucherzentrale.de/wissen/vertraege-reklamation/kundenrechte/wenn-veranstaltungen-wegen-corona-abgesagt-werden-ihre-rechte-45416
                  </a>
                  <br />
                  <br />
                  Corona und Reisen absagen
                  <br />
                  <a href="https://www.verbraucherzentrale.de/aktuelle-meldungen/reise-mobilitaet/unterwegs-sein/weltweite-coronareisewarnung-pauschalreisen-kostenlos-stornierbar-43991">
                    https://www.verbraucherzentrale.de/aktuelle-meldungen/reise-mobilitaet/unterwegs-sein/weltweite-coronareisewarnung-pauschalreisen-kostenlos-stornierbar-43991
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>Wichtige Covid-19 Telefonnummern</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Bereitschaftsdienst der Kassenärztlichen Vereinigungen: 116
                  117
                  <br />
                  Bundesgesundheitsministeriums:{' '}
                  <a href="tel:030-346465100">030-346465100</a>
                  <br />
                  Barmer Krankenkasse Hotline:{' '}
                  <a href="tel:0800-3331010">0800-3331010</a>
                  <br />
                  DAK Hotline: <a href="tel:040-325325800">040-325325800</a>
                  <br />
                  <br />
                  Bundesministerium für Ernährung und Landwirtschaft
                  <br />
                  Landwirtschaft Corona <br />
                  Telefon: <a href="tel:030-311606150">030-311606150</a> <br />
                  Email:{' '}
                  <a href="mailto:info-lw@bmel.bund.de">info-lw@bmel.bund.de</a>
                  <br />
                  <br />
                  Ernährungswirtschaft: <br />
                  Telefon: <a href="tel:030-311606154">030-311606154</a>
                  <br />
                  Email:{' '}
                  <a href="mailto:info-er@bmel.bund.de">info-er@bmel.bund.de</a>
                  <br />
                  <br />
                  Verbraucher:
                  <br />
                  Telefon: <a href="tel:030-311606158">030-311606158</a>
                  <br />
                  Email:{' '}
                  <a href="mailto:info-vb@bmel.bund.de">info-vb@bmel.bund.de</a>
                  <br />
                  <br />
                  Spezielle Corona Telefonnummer Bundesland:
                  <br />
                  Baden-Württemberg:{' '}
                  <a href="tel:0711-90439555">0711-90439555</a>
                  <br />
                  Berlin: <a href="tel:030-90282828">030-90282828</a>
                  <br />
                  Hamburg: <a href="tel:040-428284000">040-428284000</a>
                  <br />
                  Hessen: <a href="tel:0800-5554666">0800-5554666</a>
                  <br />
                  NRW: <a href="tel:0211-91191001">0211-91191001</a>
                  <br />
                  Rheinland-Pfalz: <a href="tel:0800-5758100">0800-5758100</a>
                  <br />
                  Thüringen: <a href="tel:0361-573815099">0361-573815099</a>
                  <br />
                  <br />
                  <br />
                  Telefonseelsorge: <a href="tel:0800-1110111">0800-1110111</a>
                  <br />
                  Psychologische Hilfe wegen Corona:{' '}
                  <a href="tel:0800-7772244">0800-7772244</a>
                  <br />
                  <br />
                  Patientenberatung Deutsch:{' '}
                  <a href="tel:0800-0117722">0800-0117722</a>
                  <br />
                  Patientenberatung Türkisch:{' '}
                  <a href="tel:0800-0117723">0800-0117723</a>
                  <br />
                  Patientenberatung Russisch:{' '}
                  <a href="tel:0800-0117724">0800-0117724</a>
                  <br />
                  Patientenberatung Arabisch:{' '}
                  <a href="tel:0800-33221225">0800-33221225</a>
                  <br />
                  <br />
                  Beantragung von Kurzarbeitergeld:{' '}
                  <a href="tel:0800-4555520">0800-4555520</a>
                  <br />
                  Hotline des Bundeswirtschaftsministeriums für allgemeine
                  wirtschaftsbezogene Fragen zum Coronavirus:{' '}
                  <a href="tel:030-186151515">030-186151515</a>
                  <br />
                  Hotline für Fragen zu Ausnahmegenehmigungen bei der Ausfuhr
                  von Schutzausrüstung wegen Corona:{' '}
                  <a href="tel:06196-9081444">06196-9081444</a>
                  <br />
                  <br />
                  Menschen mit Hörschwierigkeiten:
                  <br />
                  Fax: fax:030-340606607
                  <br />
                  Email:{' '}
                  <a href="mailto:info.gehoerlos@bmg.bund.de">
                    info.gehoerlos@bmg.bund.de
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>Corona Covid-19 Epidemie Zahlen</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Das Robert Koch-Institut (RKI) ist ein Bundesinstitut im
                  Geschäftsbereich des Bundesministeriums für Gesundheit. Das
                  RKI ist die zentrale Einrichtung der Bundesregierung auf dem
                  Gebiet der Krankheitsüberwachung und -prävention
                  <br />
                  <a href="https://www.rki.de/DE/Content/InfAZ/N/Neuartiges_Coronavirus/nCoV.html">
                    https://www.rki.de/DE/Content/InfAZ/N/Neuartiges_Coronavirus/nCoV.html
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>
                  Corona Pandemie wissenschaftliche Fakten
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Aktueller täglicher Situationsbericht des Robert
                  Koch-Instituts zu COVID-19
                  <br />
                  <a href="https://www.rki.de/DE/Content/InfAZ/N/Neuartiges_Coronavirus/Situationsberichte/Gesamt.html">
                    ttps://www.rki.de/DE/Content/InfAZ/N/Neuartiges_Coronavirus/Situationsberichte/Gesamt.html
                  </a>
                  <br />
                  WHO - World Health Organization - Koordinationsbehörde der
                  Vereinten Nationen für das internationale öffentliche
                  Gesundheitswesen
                  <br />
                  <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019">
                    https://www.who.int/emergencies/diseases/novel-coronavirus-2019
                  </a>
                  <br />
                  Corona und Tiermedizin
                  <br />
                  <a href="https://www.biorxiv.org/content/10.1101/2020.03.30.015347v1.full.pdf">
                    https://www.biorxiv.org/content/10.1101/2020.03.30.015347v1.full.pdf
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default GeneralInformations;
