import React from 'react';
import { Card, Container, Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../../components/header/header';
import Bounce from 'react-reveal/Bounce';
import './impressum.scss';
import BackButton from '../../components/back-button/back-button';

function Impressum(props) {
  return (
    <div className="impressum">
      <Header />
      <BackButton />
      <Container>
        <Row>
          <Col>
            <Bounce left>
              <Card>
                <Card.Header>
                  <Card.Title>Impressum</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <h1>Impressum</h1>
                    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                    <p>
                      The Friend Next Door
                      <br />
                      Kastanienweg 9
                      <br />
                      61462 Königstein
                    </p>
                    <p>
                      <strong>Vertreten durch:</strong>
                      <br />
                      Florian Niculescu
                      <br />
                      Christopher Klose
                      <br />
                      Meik Bolender
                    </p>
                    <h2>Kontakt:</h2>
                    <p>
                      Telefon: +49 (0) 6173-4483
                      <br />
                      Telefax: /
                      <br />
                      E-Mail:{' '}
                      <a href="mailto: contact@thefriendnextdoor.de">
                        contact@thefriendnextdoor.de
                      </a>
                    </p>
                    <h3>Haftung f&uuml;r Inhalte</h3>{' '}
                    <p>
                      Die Inhalte unserer Seiten wurden mit größter Sorgfalt
                      erstellt. Für die Richtigkeit, Vollständigkeit und
                      Aktualität der Inhalte können wir jedoch keine Gewähr
                      übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1
                      TMG für eigene Inhalte auf diesen Seiten nach den
                      allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG
                      sind wir als Diensteanbieter jedoch nicht verpflichtet,
                      übermittelte oder gespeicherte fremde Informationen zu
                      überwachen oder nach Umständen zu forschen, die auf eine
                      rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                      Entfernung oder Sperrung der Nutzung von Informationen
                      nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                      Eine diesbezügliche Haftung ist jedoch erst ab dem
                      Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                      möglich. Bei Bekanntwerden von entsprechenden
                      Rechtsverletzungen werden wir diese Inhalte umgehend
                      entfernen.{' '}
                    </p>
                    <h3>Haftung f&uuml;r Links</h3>
                    <p>
                      Unser Angebot enthält Links zu externen Webseiten Dritter,
                      auf deren Inhalte wir keinen Einfluss haben. Deshalb
                      können wir für diese fremden Inhalte auch keine Gewähr
                      übernehmen. Für die Inhalte der verlinkten Seiten ist
                      stets der jeweilige Anbieter oder Betreiber der Seiten
                      verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
                      der Verlinkung auf mögliche Rechtsverstöße überprüft.
                      Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung
                      nicht erkennbar. Eine permanente inhaltliche Kontrolle der
                      verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
                      einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
                      von Rechtsverletzungen werden wir derartige Links umgehend
                      entfernen.
                    </p>
                    <h3>Urheberrecht</h3>
                    <p>
                      Die durch die Seitenbetreiber erstellten Inhalte und Werke
                      auf diesen Seiten unterliegen dem deutschen Urheberrecht.
                      Die Vervielfältigung, Bearbeitung, Verbreitung und jede
                      Art der Verwertung außerhalb der Grenzen des
                      Urheberrechtes bedürfen der schriftlichen Zustimmung des
                      jeweiligen Autors bzw. Erstellers. Downloads und Kopien
                      dieser Seite sind nur für den privaten, nicht
                      kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                      dieser Seite nicht vom Betreiber erstellt wurden, werden
                      die Urheberrechte Dritter beachtet. Insbesondere werden
                      Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                      trotzdem auf eine Urheberrechtsverletzung aufmerksam
                      werden, bitten wir um einen entsprechenden Hinweis. Bei
                      Bekanntwerden von Rechtsverletzungen werden wir derartige
                      Inhalte umgehend entfernen.
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Bounce>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(Impressum);
