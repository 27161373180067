import React, { Component } from 'react';
import {
  Card,
  Form,
  Button,
  Container,
  Col,
  Row,
  Modal,
  Spinner
} from 'react-bootstrap';
import Bounce from 'react-reveal/Bounce';
import './contactForm.scss';
import Header from '../../components/header/header';
import BackButton from '../../components/back-button/back-button';
import { postContact } from '../../utils/fetch';

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      message: '',
      email: '',
      isLoading: false,
      done: false,
      errors: {
        title: '',
        message: '',
        email: ''
      },
      initialValidation: false,
      invalidForm: false
    };
  }
  render() {
    const { isLoading, done, errors, invalidForm } = this.state;

    return (
      <div className="contact-form">
        <Header />

        <BackButton />
        <Container>
          <Row>
            <Col>
              {!isLoading && !done ? (
                <Bounce left>
                  <Card>
                    <Card.Header>
                      <Card.Title>Kontaktformular</Card.Title>
                      <Card.Subtitle>
                        Hast du Anregungen an uns? Einen Fehler entdeckt oder
                        Unsachgemäße Inhalte gesichtet ? Dann kannst du uns hier
                        dein Anliegen schildern. Wir versuchen so bald wie
                        möglich darauf zu reagieren :)
                      </Card.Subtitle>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label>Betreff</Form.Label>
                          <Form.Control
                            type="text"
                            name="title"
                            required
                            onChange={event => this.handleChange(event)}
                            onFocus={() =>
                              this.setState({ invalidForm: false })
                            }
                            formNoValidate
                            maxLength="80"
                          />
                          <span className="error">{errors.title}</span>
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Deine Nachricht</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="message"
                            rows="10"
                            onChange={event => this.handleChange(event)}
                            onFocus={() =>
                              this.setState({ invalidForm: false })
                            }
                            formNoValidate
                            maxLength="1000"
                          />
                          <span className="error">{errors.message}</span>
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlInput2">
                          <Form.Label>Email adresse</Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            onChange={event => this.handleChange(event)}
                            onFocus={() =>
                              this.setState({ invalidForm: false })
                            }
                            formNoValidate
                            maxLength="100"
                          />
                          <span className="error">{errors.email}</span>
                        </Form.Group>
                      </Form>
                    </Card.Body>
                    <Card.Footer>
                      <div>
                        {invalidForm && (
                          <div className="invalid-form">
                            Bitte alle Felder ausfüllen.
                          </div>
                        )}
                      </div>
                      <Button
                        variant="primary"
                        onClick={event => this.handleSubmit(event)}
                      >
                        Absenden
                      </Button>
                    </Card.Footer>
                  </Card>
                </Bounce>
              ) : isLoading && !done ? (
                <div className="loading-spinner">
                  <Spinner animation="grow" variant="info" />
                </div>
              ) : (
                done && (
                  <div>
                    Vielen Dank, du wirst schnellstmöglich eine Antwort
                    erhalten.
                  </div>
                )
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    const { errors } = this.state;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    switch (name) {
      case 'title':
        errors.title =
          value.length <= 0 ? 'Bitte gib einen längeren Betreff ein.' : '';
        break;
      case 'email':
        errors.email = validEmailRegex.test(value)
          ? ''
          : 'Diese Email hat das falsche Format.';
        break;
      case 'message':
        errors.message =
          value.length <= 0 ? 'Bitte gib eine längere Nachricht ein.' : '';
        break;

      default:
        break;
    }
    this.setState({
      errors,
      [name]: value,
      initialValidation: true,
      invalidForm: false
    });
  };

  handleSubmit = event => {
    const { errors } = this.state;
    event.preventDefault();

    if (this.validateForm(errors)) {
      this.handleContactSend();
    } else {
      console.error('Invalid Form');
      return;
    }
  };

  validateForm = error => {
    const { initialValidation, title, message, email } = this.state;
    let valid = true;
    if (!initialValidation || !title || !email || !message) {
      valid = false;
      this.setState({ invalidForm: true });
      return;
    }

    Object.values(error).forEach(val => val.length > 0 && (valid = false));
    this.setState({ invalidForm: false });
    return valid;
  };

  handleContactSend = async token => {
    //add async key word before the token variable
    const { title, message, email } = this.state;
    this.setState({ isLoading: true });
    if (title === '' || message === '' || email === '') {
      this.setState({ errorModal: true, isLoading: false });
    } else {
      try {
        await postContact(title, message, email).then(res => {
          if (res.status === 200) {
            this.setState({ isLoading: false });
            this.setState({ done: true });
          }
        });
      } catch (e) {
        console.error(e);
      }
    }
  };
}

export default ContactForm;
