import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './modal.scss';

const ModalComponent = props => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.open}
      onHide={props.onClose}
    >
      <Modal.Header closeButton onHide={props.onClose}>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.type === 'contact' ? (
          <Form>
            <Form.Group controlId="formTitel">
              <Form.Label>Titel</Form.Label>
              <Form.Control
                type="text"
                required
                onChange={e => {
                  this.setState({
                    titel: e.target.value
                  });
                }}
              />
            </Form.Group>
            <Form.Group controlId="formTitel">
              <Form.Label>Beschreibung</Form.Label>
              <Form.Control as="textarea" type="text" required />
            </Form.Group>

            <Form.Group controlId="formTitel">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" required />
              <Form.Text className="text-muted">
                Beispiel: maxmustermann@muster.de
              </Form.Text>
            </Form.Group>
          </Form>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: `${props.text}` }} />
        )}
      </Modal.Body>
      <Modal.Footer>
        {props.type === 'report' && (
          <Button variant="primary" onClick={props.onClose}>
            Melden
          </Button>
        )}
        {props.type === 'delete' && (
          <div>
            <Button variant="danger" onClick={props.onDelete}>
              Löschen
            </Button>
          </div>
        )}
        {props.type === 'contact' && (
          <div>
            <Button variant="primary" onClick={props.onSend}>
              Abschicken
            </Button>
          </div>
        )}
        {props.type === 'message' && (
          <div>
            <Button variant="primary" onClick={props.onSend}>
              Alles klar!
            </Button>
          </div>
        )}

        {props.type === 'success' && (
          <div>
            <Button variant="primary" onClick={props.onClose}>
              Alles klar!
            </Button>
          </div>
        )}
        {props.type === 'failed' && (
          <div>
            <Button variant="primary" onClick={props.onClose}>
              Alles klar!
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;
