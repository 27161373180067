import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  Link,
  ListSubheader
} from '@material-ui/core/';
import { withRouter } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import MailIcon from '@material-ui/icons/MailOutline';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PanToolIcon from '@material-ui/icons/PanTool';
import Home from '@material-ui/icons/Home';
import './drawer.scss';

function DrawerMenu(props) {
  const { history } = props;
  const _logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('location');
    localStorage.removeItem('rangeValue');
    props.history.push({
      pathname: '/signin'
      // state: {}
    });
  };
  const username = localStorage.getItem('username');
  return (
    <Drawer anchor="left" open={props.isOpen} onClose={props.handleOnClose}>
      <div className="menu-drawer">
        <List>
          <ListSubheader>
            <div>{username}</div>
          </ListSubheader>
          <Divider />
          <div
            onClick={() => {
              props.history.push('/');
            }}
          >
            <ListItem button>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <Link>Home</Link>
            </ListItem>
          </div>
          <div onClick={() => history.push('/faq')}>
            <ListItem button>
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <Link>Häufig gestellte Fragen</Link>
            </ListItem>
          </div>
          <div
            onClick={() => history.push('/general-information-corona-covid19')}
          >
            <ListItem button>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <Link>
                Allgemeine Informationen <br />
                zu Corona-Covid19
              </Link>
            </ListItem>
          </div>
        </List>
        <Divider />
        <List>
          <div onClick={() => history.push('/contact')}>
            <ListItem button>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <Link>Kontakt</Link>
            </ListItem>
          </div>
          <div onClick={() => history.push('/informations')}>
            <ListItem button>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <Link>Informationen</Link>
            </ListItem>
          </div>
          <div onClick={() => _logout()} className="logout">
            <ListItem button>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <Link>Logout</Link>
            </ListItem>
          </div>
        </List>
      </div>
    </Drawer>
  );
}

export default withRouter(DrawerMenu);
