import React from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { isInStandaloneMode, isIos } from '../../utils/misc';
import './back-button.scss';

function BackButton(props) {
  const goBack = () => {
    props.history.goBack();
  };
  return (
    <div
      className={`back-button ${isInStandaloneMode() && isIos() && 'isIos'}`}
    >
      <Button onClick={goBack}>Zurück</Button>
    </div>
  );
}

export default withRouter(BackButton);
