import React from 'react';
import { Accordion, Container, Row, Col } from 'react-bootstrap';
import AccordionItem from '../../components/accordion-item/accordion-item';
import BackButton from '../../components/back-button/back-button';
import Header from '../../components/header/header';
import Bounce from 'react-reveal/Bounce';
import { faqs } from '../../utils/misc';
import './faq.scss';

function Faq() {
  return (
    <div className="faq">
      <Header />
      <BackButton />
      <Container>
        <Row>
          <Col>
            <div className="faq-headline">
              <h1>Häufig gestellte Fragen</h1>
            </div>
          </Col>
        </Row>
        <Bounce left>
          <Row>
            <Col>
              <Accordion defaultActiveKey="0">
                {faqs.map((item, id) => {
                  return (
                    <div key={id}>
                      <AccordionItem
                        accordionTitle={item.title}
                        accordionText={item.text}
                        eventKey={id}
                      />
                    </div>
                  );
                })}
              </Accordion>
            </Col>
          </Row>
        </Bounce>
      </Container>
    </div>
  );
}

export default Faq;
