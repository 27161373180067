import React, { Component, Fragment } from 'react';
import { fetchSignUp } from '../../utils/fetch';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner
} from 'react-bootstrap';
import Footer from '../../components/footer/footer';
import Flip from 'react-reveal/Flip';
import ModalComponent from '../../components/modal/modal';
import Header from '../../components/header/header';
import './signup.scss';

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      email: '',
      password: '',
      repeatedPassword: '',
      isLoading: false,
      privacy: false,
      dataPrivacy: false,
      errorModal: false,
      errors: {
        username: '',
        email: '',
        password: '',
        repeatedPassword: '',
        privacy: '',
        dataPrivacy: ''
      },
      initialValidation: false,
      invalidForm: false
    };
  }

  componentDidMount() {
    // this.isAuthenticated();
  }

  render() {
    const { isLoading, errors, errorModal, invalidForm } = this.state;
    return (
      <div className="signup">
        <Header />
        <ModalComponent
          title="Fehler"
          text="Bitte akzeptiere unsere Nutzungsbedingungen und
          Datenschutzrichtlinien."
          open={errorModal}
          onClose={() => this.setState({ errorModal: !this.state.errorModal })}
        />

        <Container>
          <Row>
            <Col xs={12} sm={12} md={7} lg={7}>
              {!isLoading ? (
                <Flip right>
                  <Card>
                    <Card.Header>
                      <Card.Title>Account erstellen</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                        <Fragment>
                          <Form.Group>
                            <Form.Label>Benutzername</Form.Label>
                            <Form.Control
                              type="text"
                              required
                              onChange={event => this.handleChange(event)}
                              onFocus={() =>
                                this.setState({ invalidForm: false })
                              }
                              name="username"
                              formNoValidate
                            />
                            <span className="error">{errors.username}</span>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="password"
                              name="password"
                              required
                              onChange={event => this.handleChange(event)}
                              onFocus={() =>
                                this.setState({ invalidForm: false })
                              }
                              formNoValidate
                            />

                            <span className="error">{errors.password}</span>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Password wiederholen</Form.Label>
                            <Form.Control
                              type="password"
                              name="repeatedPassword"
                              required
                              onChange={event => this.handleChange(event)}
                              onFocus={() =>
                                this.setState({ invalidForm: false })
                              }
                              formNoValidate
                            />

                            <span className="error">
                              {errors.repeatedPassword}
                            </span>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              required
                              onChange={event => this.handleChange(event)}
                              onFocus={() =>
                                this.setState({ invalidForm: false })
                              }
                              formNoValidate
                            />

                            <span className="error">{errors.email}</span>
                          </Form.Group>
                          <div>
                            {invalidForm && (
                              <div className="invalid-form">
                                Bitte alle Felder ausfüllen.
                              </div>
                            )}
                          </div>

                          <div className="privacys">
                            <div>
                              <input
                                type="checkbox"
                                name="privacy"
                                onChange={e => {
                                  this.setState({
                                    privacy: e.target.checked
                                  });
                                }}
                              />
                              <Link to="/privacies">Nutzungsbedingungen</Link>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                name="privacy"
                                onChange={e => {
                                  this.setState({
                                    dataPrivacy: e.target.checked
                                  });
                                }}
                              />
                              <Link to="/data-privacies">
                                Datenschutzrichtlinien
                              </Link>
                            </div>
                          </div>
                          <div className="submit-button">
                            <Button
                              variant="primary"
                              block
                              onClick={event => this.handleSubmit(event)}
                            >
                              Kostenlos Anmelden!
                            </Button>
                          </div>
                        </Fragment>
                      </Form>
                    </Card.Body>
                    <div className="signup-register">
                      <Link to="/signin">Zurück zum Login?</Link>
                    </div>
                  </Card>
                </Flip>
              ) : (
                <div className="loading-spinner">
                  <Spinner animation="grow" variant="info" />
                </div>
              )}
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }

  handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    const { errors } = this.state;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    switch (name) {
      case 'username':
        errors.username =
          value.length <= 1 ? 'Dieser Benutzername ist zu kurz.' : '';
        break;
      case 'email':
        errors.email = validEmailRegex.test(value)
          ? ''
          : 'Diese Email hat das falsche Format.';
        break;
      case 'password':
        errors.password =
          value.length < 8
            ? 'Das Passwort muss aus mindestens 8 Zeichen bestehen.'
            : '';
        break;
      case 'repeatedPassword':
        errors.repeatedPassword =
          value !== this.state.password
            ? 'Passwörter stimmen nicht über ein.'
            : '';

      default:
        break;
    }
    this.setState({
      errors,
      [name]: value,
      initialValidation: true,
      invalidForm: false
    });
  };

  handleSubmit = event => {
    const { errors, username, password, email } = this.state;
    event.preventDefault();

    if (this.validateForm(errors)) {
      this._signup(username, password, email);
    } else {
      console.error('Invalid Form');
      return;
    }
  };

  validateForm = error => {
    const {
      initialValidation,
      username,
      email,
      password,
      repeatedPassword
    } = this.state;
    let valid = true;
    if (
      !initialValidation ||
      !username ||
      !email ||
      !password ||
      !repeatedPassword
    ) {
      valid = false;
      this.setState({ invalidForm: true });
      return;
    }

    Object.values(error).forEach(val => val.length > 0 && (valid = false));
    this.setState({ invalidForm: false });
    return valid;
  };

  _signup = async () => {
    const {
      username,
      password,
      email,
      privacy,
      dataPrivacy,
      errors
    } = this.state;

    if (!privacy && !dataPrivacy) {
      this.setState({ errorModal: true, invalidForm: true });
      return;
    } else if (!dataPrivacy) {
      this.setState({ errorModal: true, invalidForm: true });
      return;
    } else if (!privacy) {
      this.setState({ errorModal: true, invalidForm: true });
      return;
    }
    this.setState({ isLoading: true });
    fetchSignUp(username, password, email).then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          if (data.statusText === 'username') {
            errors.username = 'Benutzername bereits vergeben.';

            this.setState({
              privacy: false,
              dataPrivacy: false,
              errors,
              isLoading: false
            });
          } else if (data.statusText === 'email') {
            errors.email = 'Email bereits vergeben.';

            this.setState({
              privacy: false,
              dataPrivacy: false,
              errors,
              isLoading: false
            });
          } else {
            this.setState({ isLoading: false });
            this.props.history.push('/signin');
          }
        });
      } else {
        console.error(response.status);
      }
    });
  };
}

export default SignUp;
