import React from 'react';
import { Button } from 'react-bootstrap';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import { isInStandaloneMode, isIos } from '../../utils/misc';
import './location-button.scss';

function LocationButton(props) {
  const resetLocation = () => {
    localStorage.removeItem('location');
    window.location.reload();
  };

  return (
    <div
      className={`location-button ${isInStandaloneMode() &&
        isIos() &&
        'isIos'}`}
    >
      <Button onClick={resetLocation}>
        <GpsFixedIcon style={{ width: '30px', height: '30px' }} />
      </Button>
    </div>
  );
}

export default LocationButton;
