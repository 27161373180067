import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import {
  Spinner,
  Button,
  Container,
  Dropdown,
  Col,
  Row
} from 'react-bootstrap';
import Header from '../../components/header/header';
import LocationPermissionModal from '../../components/locationPermissionModal/locationPermissionModal';
import LocationButton from '../../components/location-button/location-button';
import './main-page.scss';
import ListComponent from '../list/list';
import Footer from '../../components/footer/footer';
import AddIcon from '@material-ui/icons/Add';
import { Form } from 'react-bootstrap';
import RangeSlider from '../../components/rangeSlider/rangeSlider';
import { connect } from 'react-redux';
import { fetchList, fetchOwnJobs } from '../../utils/fetch';
import { setToken } from '../../redux/actions/applications.action';

class MainPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      announcementList: [],
      memoryList: [],
      isLoading: false,
      coords: { longitude: null, latitude: null, accuracy: null },
      abc: false,
      showMap: true,
      list: [],
      ownList: []
    };
  }

  componentDidMount() {
    this.getList();
    this.getOwnJobs();

    this.timer = 0;
    this.responseTimer = setInterval(() => {
      this.timer++;
      if (this.timer === 2 && this.rsValue) this.getList();
    }, 400);
  }

  getList = async () => {
    this.setState({ isLoading: true });
    const token = localStorage.getItem('token');
    const rangeValue = localStorage.getItem('rangeValue');
    const max_distance = rangeValue ? rangeValue : 25;

    const locInterval = setInterval(async () => {
      const coords = JSON.parse(localStorage.getItem('location'));

      if (coords != null) {
        try {
          await fetchList(
            coords.latitude,
            coords.longitude,
            coords.accuracy,
            max_distance,
            token
          ).then(res => {
            if (res.status === 200) {
              res.json().then(data => {
                this.setState({
                  list: data,
                  announcementList: data,
                  memoryList: data,
                  isLoading: false
                });
              });
            }
          });
        } catch (e) {
          console.error(e);
        }

        clearInterval(locInterval);
      }
    }, 1000);
  };

  getOwnJobs = async () => {
    const token = localStorage.getItem('token');

    try {
      await fetchOwnJobs(token).then(res => {
        if (res.status === 200) {
          res.json().then(data => {
            this.setState({ ownList: data });
          });
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleSearch = value => {
    const { memoryList } = this.state;
    const filteredList = memoryList.filter(employee => {
      const listItem = employee.title.toLowerCase();
      const searchTerm = value.toLowerCase();
      return listItem.indexOf(searchTerm) > -1;
    });
    this.setState({ announcementList: filteredList });
  };

  filterForLatest = () => {
    const { memoryList } = this.state;
    const latest = memoryList.reverse();
    this.setState({ announcementList: latest });
  };

  filterForSearching = () => {
    const { memoryList } = this.state;
    const type = memoryList.filter(obj => {
      return obj.type === 0;
    });
    this.setState({ announcementList: type });
  };

  filterForOffering = () => {
    const { memoryList } = this.state;
    const type = memoryList.filter(obj => {
      return obj.type === 1;
    });
    this.setState({ announcementList: type });
  };

  filterForOwn = () => {
    const { memoryList } = this.state;
    const own = memoryList.filter(obj => {
      return obj.self === true;
    });
    this.setState({ announcementList: own });
  };

  filterForLowDistance = () => {
    const { memoryList } = this.state;
    const lowDistance = memoryList.filter(obj => {
      return obj.distance <= 1;
    });
    this.setState({ announcementList: lowDistance });
  };

  goToAddScreen = () => {
    const { history } = this.props;
    history.push('/addnew');
  };

  goToDetails = id => {
    const { announcementList } = this.state;

    this.props.history.push({
      pathname: `/details/${id}`,
      state: announcementList[id]
    });
  };

  handleRSliderChange = value => {
    this.rsValue = value;
    this.timer = 0;
  };

  render() {
    const { announcementList, isLoading, showMap } = this.state;
    const isInStandaloneMode = () =>
      'standalone' in window.navigator && window.navigator.standalone;
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };

    return (
      <div className="main-page">
        <LocationPermissionModal show={showMap} />
        <Header />
        <div
          className={`main-page-search-container ${isInStandaloneMode() &&
            isIos() &&
            'isIos'}`}
        >
          <Container>
            <Row>
              <RangeSlider
                handleRSliderChange={this.handleRSliderChange}
              />
              <LocationButton />
            </Row>
            <Row>
              <Col xs={6} sm={7}>
                <Form.Group controlId="formTitel">
                  <Form.Control
                    placeholder="Suche"
                    type="text"
                    onChange={e => this.handleSearch(e.target.value)}
                  />
                  <Form.Text className="text-muted">
                    Beispiel: Einkaufen
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col xs={6} sm={3} className="dropdown-container">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-primary"
                    id="dropdown-basic"
                  >
                    Filtern nach
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={this.filterForLatest}>
                      Neuste
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.filterForOwn}>
                      Eigene
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.filterForLowDistance}>
                      Sehr nah
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.filterForSearching}>
                      Suche
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.filterForOffering}>
                      Biete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="main-page-list-container">
          {isLoading ? (
            <div className="loading-spinner">
              <Spinner animation="grow" variant="info" />
            </div>
          ) : (
              <Container>
                <Row>
                  <Col>
                    <div className="list-length">
                      <div className="list-entries">
                        <p className="list-lengt-text">
                          {announcementList.length} Einträge aus deiner Umgebung
                      </p>
                      </div>
                    </div>
                  </Col>
                </Row>

                <ListComponent
                  list={announcementList}
                  goToDetails={this.goToDetails}
                />
              </Container>
            )}
        </div>
        <div className="add-button">
          <Button variant="primary" onClick={this.goToAddScreen}>
            <AddIcon style={{ width: '40px', height: '40px' }} />
          </Button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setToken: token => {
      dispatch(setToken(token));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(MainPage);
