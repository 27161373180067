import React from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';
import './tooltip.scss';
import img from '../../media/iosshare.jpg';

function Tooltip() {
  return (
    <div className="custom-tooltip">
      <div className="custom-tooltip-icon">
        <AddBoxIcon />
      </div>
      <div className="custom-tooltiptext">
        Diese Webapp installieren: Drücke{' '}
        <span className="custom-tooltip-img">
          <img src={img} alt="ios-share" />
        </span>
        und wähle "Zum Homescreen hinzufügen".
      </div>
    </div>
  );
}

export default Tooltip;
