import { host } from './misc';

export const fetchLogin = (username, password) => {
  return fetch(host + 'login', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username: username,
      password: password
    })
  });
};

export const checkToken = token => {
  return fetch(host + 'login', {
    method: 'get',
    headers: {
      Authorization: 'bearer ' + token
    }
  }).then(async response => {
    const data = await response.json();

    return data;
  });
};

export const fetchSignUp = (username, password, email) => {
  return fetch(host + 'signup', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username: username,
      password: password,
      email: email
    })
  });
};

export const fetchList = (
  latitude,
  longitude,
  accuracy,
  max_distance,
  token
) => {
  return fetch(host + 'radar', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + token
    },
    body: JSON.stringify({
      pos_lat: latitude,
      pos_long: longitude,
      accuracy: accuracy,
      max_distance: max_distance
    })
  });
};

export const fetchAll = token => {
  return fetch(host + 'showall', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + token
    }
  });
};

export const addJob = (
  title,
  description,
  type,
  pos_long,
  pos_lat,
  token,
  active,
  categories
) => {
  return fetch(host + 'addjob', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + token
    },
    body: JSON.stringify({
      title: title,
      description: description,
      type: type,
      pos_long: pos_long,
      pos_lat: pos_lat,
      active,
      categories
    })
  });
};

export const fetchOwnJobs = token => {
  return fetch(host + '/ownjobs', {
    method: 'get',
    headers: {
      Authorization: 'bearer ' + token
    }
  });
};

export const postContact = (subject, message, email) => {
  return fetch(host + 'contact', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      subject: subject,
      message: message,
      email: email
    })
  });
};

export const fetchDetails = (token, id) => {
  return fetch(host + `entry/${id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + token
    }
  });
};

export const deleteJob = (token, id) => {
  return fetch(host + `deljob/${id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + token
    }
  });
};

export const postViewCounter = (token, id) => {
  return fetch(host + 'jobwcnt', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + token
    },
    body: JSON.stringify({
      jobID: id
    })
  });
};

export const postMessage = (token, userID, username, message, title, type) => {
  return fetch(host + 'smsg', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + token
    },
    body: JSON.stringify({
      target_usrID: userID,
      username: username,
      pe_msg: message,
      ext_title: title,
      type: type
    })
  });
};

export const fetchProfile = token => {
  return fetch(host + 'profile', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + token
    }
  });
};

export const postPasswordRest = email => {
  return fetch(host + 'password_reset', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: email
    })
  });
};
