import React from 'react';
import ListItem from '../../components/list-item/list-item';
import { Alert, Container, Row, Col } from 'react-bootstrap';
import Bounce from 'react-reveal/Bounce';
import './list.scss';

const ListComponent = props => {
  const reversedList = props.list.reverse();
  return (
    <div className="list">
      {reversedList.length > 0 ? (
        reversedList.map((item, i) => {
          return (
            <div key={i} onClick={() => props.goToDetails(item.jobsID)}>
              <Bounce left>
                <Row>
                  <Col>
                    <ListItem
                      title={item.title}
                      description={item.description}
                      type={item.type}
                      distance={item.distance}
                    />
                  </Col>
                </Row>
              </Bounce>
            </div>
          );
        })
      ) : (
        <Bounce left>
          <Container fluid>
            <Row>
              <Col>
                <Alert variant="info">Keine Einträge für deinen Standort</Alert>
              </Col>
            </Row>
          </Container>
        </Bounce>
      )}
    </div>
  );
};

export default ListComponent;
