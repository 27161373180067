import React, { useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import DrawerMenu from '../../components/drawer/drawer';
import { withRouter } from 'react-router-dom';
import './header.scss';

function Header(props) {
  const { location, history } = props;
  const isInStandaloneMode = () =>
    'standalone' in window.navigator && window.navigator.standalone;
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };
  const [isOpen, setOpen] = useState(false);

  const token = localStorage.getItem('token');

  const toggleDrawer = () => {
    setOpen(!isOpen);
  };
  const goToLogin = () => {
    history.push('/signin');
  };
  const goToRegister = () => {
    history.push('/signup');
  };
  const goToHome = () => {
    history.push('/');
  };
  let renderLoginButton =
    (location.pathname === '/' && !token) || location.pathname === '/signup';

  let renderRegisterButton = location.pathname === '/signin';

  return (
    <div
      className={`main-page-header ${isInStandaloneMode() &&
        isIos() &&
        'notch'}`}
    >
      <DrawerMenu handleOnClose={toggleDrawer} isOpen={isOpen} />
      <div className="header-button-container">
        <div className="header-button">
          {renderLoginButton && <Button onClick={goToLogin}>Anmelden</Button>}
          {token && (
            <Button variant="primary" onClick={toggleDrawer}>
              Menü
            </Button>
          )}
          {renderRegisterButton && (
            <Button onClick={goToRegister}>Registrieren</Button>
          )}
        </div>
      </div>
      <div className="main-page-headline-container">
        <div className="main-page-headline">
          <p>The Friend Next Door</p>
        </div>
        <div className="main-page-subline">
          <a href="/general-information-corona-covid19">#StayAtHome</a>
        </div>
      </div>
      <div className="header-image-container" onClick={goToHome}>
        <div className="header-image">
          <Image
            src="../../../images/logo_white_active.png"
            alt="TheFriendNextDoor"
            rounded
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(Header);
