import React, { Component } from 'react';
import {
  Button,
  Form,
  Card,
  Container,
  Row,
  Col,
  Spinner
} from 'react-bootstrap';
import './addJob.scss';
import { addJob } from '../../utils/fetch';
import Bounce from 'react-reveal/Bounce';
import Header from '../../components/header/header';
import BackButton from '../../components/back-button/back-button';
import Typeswitcher from '../../components/typeswitcher/typeswitcher';
import ModalComponent from '../../components/modal/modal';

class AddJob extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: '0',
      isOpen: false,
      title: '',
      description: '',
      isError: false,
      isLoading: false,
      successfull: false,
      failed: false,
      errors: {
        title: '',
        description: ''
      },
      initialValidation: false,
      invalidForm: false
    };
  }

  render() {
    const { isLoading, successfull, errors, invalidForm, failed } = this.state;

    return (
      <div className="add-job">
        <Header />
        <BackButton />
        <ModalComponent
          title="Erfolgreich!"
          text="Du hast deine Anzeige erfolgreich veröffentlicht. Sobald sich jemand auf deine Anzeige meldet, erhältst du eine E-Mail in deinem Postfach."
          open={successfull}
          onSuccess={() => this.closeSuccessModal()}
          type="success"
          onClose={() => {
            this.closeSuccessModal();
          }}
        />
        <ModalComponent
          title="Fehler"
          text="Etwas ist schief gelaufen. Überprüfe deine Internetverbindung."
          open={failed}
          failed={() => this.closeFailedModal()}
          type="failed"
          onClose={() => {
            this.closeFailedModal();
          }}
        />
        <Container>
          <Row>
            <Col lg={10} md={12}>
              {!isLoading ? (
                <Bounce left>
                  <Card>
                    <Card.Header>
                      <Card.Title id="contained-modal-title-vcenter">
                        Erstelle eine neue Anzeige
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Typeswitcher handleTypeChange={this.handleTypeChange} />
                      <Form>
                        <Form.Group controlId="formTitel">
                          <Form.Label>Titel</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            onChange={event => this.handleChange(event)}
                            name="title"
                            formNoValidate
                            maxLength="50"
                          />
                          <span className="error">{errors.title}</span>
                          <Form.Text className="text-muted">
                            Gib einen aussagekräftigen Titel an, damit andere
                            deine Anzeige schnell finden können.
                          </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formTitel">
                          <Form.Label>Beschreibung</Form.Label>
                          <Form.Control
                            as="textarea"
                            type="text"
                            required
                            onChange={event => this.handleChange(event)}
                            name="description"
                            formNoValidate
                            maxLength="1000"
                            className="input-description"
                          />
                          <span className="error">{errors.description}</span>
                          <Form.Text className="text-muted">
                            Erklär' deinen potenziellen Helfern dein Anliegen
                          </Form.Text>
                        </Form.Group>
                      </Form>
                    </Card.Body>
                    <Card.Footer>
                      <Row>
                        <Col>
                          <div>
                            {invalidForm && (
                              <div className="invalid-form">
                                Bitte alle Felder ausfüllen.
                              </div>
                            )}
                          </div>
                          <Button
                            variant="primary"
                            onClick={event => this.handleSubmit(event)}
                          >
                            Veröffentlichen
                          </Button>
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Card>
                </Bounce>
              ) : (
                <div className="loading-spinner">
                  <Spinner animation="grow" variant="info" />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    const { errors } = this.state;

    switch (name) {
      case 'title':
        errors.title =
          value.length <= 5 ? 'Bitte gib einen längeren Titel an.' : '';
        break;
      case 'description':
        errors.description =
          value.length <= 5 ? 'Bitte gib eine längere Beschreibung an.' : '';
        break;

      default:
        break;
    }
    this.setState({
      errors,
      [name]: value,
      initialValidation: true,
      invalidForm: false
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const token = localStorage.getItem('token');
    const location = JSON.parse(localStorage.getItem('location'));
    const { errors, title, description, type, active, categories } = this.state;

    if (this.validateForm(errors)) {
      this.submitJob(
        title,
        description,
        type,
        location.longitude,
        location.latitude,
        token,
        active,
        categories
      );
    } else {
      console.error('Invalid Form');
      return;
    }
  };

  validateForm = error => {
    const { initialValidation, title, description } = this.state;
    let valid = true;
    if (!initialValidation || !title || !description) {
      valid = false;
      this.setState({ invalidForm: true });
      return;
    }

    Object.values(error).forEach(val => val.length > 0 && (valid = false));
    this.setState({ invalidForm: false });
    return valid;
  };

  submitJob = async (
    title,
    description,
    type,
    long,
    lat,
    token,
    active,
    categories
  ) => {
    this.setState({ isLoading: true });
    try {
      await addJob(
        title,
        description,
        type,
        long,
        lat,
        token,
        active,
        categories
      ).then(data => {
        if (data.status === 200) {
          this.setState({ isLoading: false, successfull: true });
        } else {
          this.setState({ isLoading: false, failed: true });
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleOptionChange = event => {
    this.setState({
      type: event.target.value
    });
  };

  handleTypeChange = type => {
    this.setState({ type: type });
  };

  closeSuccessModal = () => {
    this.setState({ successfull: false });
    this.props.history.push('/');
  };

  closeFailedModal = () => {
    this.setState({ failed: false });
  };
}

export default AddJob;
