import React from 'react';
import { Card, Container, Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../../components/header/header';
import Bounce from 'react-reveal/Bounce';
import './data-privacies.scss';
import BackButton from '../../components/back-button/back-button';

function DataPrivacies(props) {
  return (
    <div className="privacies">
      <Header />
      <BackButton />
      <Container>
        <Row>
          <Col>
            <Bounce left>
              <Card>
                <Card.Header>
                  <Card.Title>Datenschutzrichtlinien</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <h3>Erklärung</h3>
                    <p>
                      Uns ist bewusst, dass Ihnen der sorgfältige Umgang mit
                      Ihren persönlichen Daten wichtig ist. Deshalb ist es uns
                      wichtig, dass Sie sich auf unserer Platform wohlfühlen und
                      sich keine gedanken um Ihre personenbezogenen Daten machen
                      müssen. Diese Datenschutzerklärung beschreibt, wie wir bei
                      TheFriendNextDoor Ihre persönlichen Informationen erfassen
                      und verarbeiten
                    </p>
                    <h3>Datenschutz</h3>
                    <p>
                      Die Nutzung unserer Webseite ist in der Regel ohne Angabe
                      personenbezogener Daten möglich. Soweit auf unseren Seiten
                      personenbezogene Daten (beispielsweise Name, Anschrift
                      oder eMail-Adressen) erhoben werden, erfolgt dies, soweit
                      möglich, stets auf freiwilliger Basis. Diese Daten werden
                      ohne Ihre ausdrückliche Zustimmung nicht an Dritte
                      weitergegeben. Wir weisen darauf hin, dass die
                      Datenübertragung im Internet (z.B. bei der Kommunikation
                      per E-Mail oder Formular) Sicherheitslücken aufweisen
                      kann. Ein lückenloser Schutz der Daten vor dem Zugriff
                      durch Dritte ist nicht möglich. Der Nutzung von im Rahmen
                      der Impressumspflicht veröffentlichten Kontaktdaten durch
                      Dritte zur Übersendung von nicht ausdrücklich
                      angeforderter Werbung und Informationsmaterialien wird
                      hiermit ausdrücklich widersprochen. Die Betreiber der
                      Seiten behalten sich ausdrücklich rechtliche Schritte im
                      Falle der unverlangten Zusendung von Werbeinformationen,
                      etwa durch Spam-Mails, vor.
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Bounce>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(DataPrivacies);
