import {
  APPLICATION_SET_LOCATION,
  APPLICATION_SET_TOKEN
} from '../action.collection';

export const setLocation = data => ({
  payload: data,
  type: APPLICATION_SET_LOCATION
});

export const setToken = data => ({
  payload: data,
  type: APPLICATION_SET_TOKEN
});
