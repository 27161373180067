import React, { Component } from 'react';
import { Map as LeafletMap, TileLayer, Marker } from 'react-leaflet';
import { connect } from 'react-redux';
import './leaflet.css';
import './map.scss';

class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coords: {
        latitude: 55,
        longitude: 8
      },
      zoom: 10,
      positionJson: [],
      positionKey: 0
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.coords !== this.props.coords) {
      this.setState({ coords: this.props.coords });
    }
  }

  componentDidMount() {
    this.setState({ coords: this.props.coords });
  }

  addMarker = e => {
    this.setState({
      coords: {
        latitude: e.latlng.lat,
        longitude: e.latlng.lng
      }
    });
    this.props.changeLocation(this.state.coords);
  };

  render() {
    const { coords } = this.state;
    const { variant } = this.props;
    return coords.longitude !== 0 && variant !== 'manual' ? (
      <LeafletMap center={[coords.latitude, coords.longitude]} zoom={14}>
        <TileLayer
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[coords.latitude, coords.longitude]}></Marker>
      </LeafletMap>
    ) : (
      <LeafletMap center={[50, 8]} zoom={6} onClick={this.addMarker}>
        <TileLayer
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {coords && (
          <Marker
            key={`marker-manual`}
            position={[coords.latitude, coords.longitude]}
          ></Marker>
        )}
      </LeafletMap>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Map);
