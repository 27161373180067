import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { Link } from '@material-ui/core/';
import './footer.scss';

function Footer(props) {
  return (
    <div className="footer">
      <Container className="footer-container">
        <Row>
          <Col>
            <ul>
              <li>
                <Link onClick={() => props.history.push('/impressum')}>
                  Impressum
                </Link>
              </li>
              <li>
                <Link onClick={() => props.history.push('/data-privacies')}>
                  Datenschutz
                </Link>
              </li>
              <li>
                <Link onClick={() => props.history.push('/contact')}>
                  Kontakt
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(Footer);
