import React, { useState, useEffect } from 'react';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Tooltip from '../../components/tooltip/tooltip';
import { withRouter } from 'react-router-dom';
import Bounce from 'react-reveal/Bounce';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import './landingpage.scss';

function Landingpage(props) {
  const [showInstallMessage, setInstallMessage] = useState(false);

  useEffect(() => {
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      console.log('isIos: ' + /iphone|ipad|ipod/.test(userAgent));
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      'standalone' in window.navigator && window.navigator.standalone;
    console.log('is in standalone Mode: ' + isInStandaloneMode());
    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      setInstallMessage(true);
    }
  }, []);

  return (
    <div className="landingpage">
      <Header />

      <div className="image-container">
        <div className="image-center">
          <div className="image-headline">
            <div className="image-main-headline">
              <h1>The Friend Next Door</h1>
            </div>
            <div className="image-sub-headline">
              <h2>Die Nachbarschaftshilfe</h2>
            </div>
          </div>
          <div className="divider" />
          <div className="image-button">
            <Button onClick={() => props.history.push('/signin')}>
              Jetzt starten
            </Button>
          </div>
        </div>
        <div className="image-bottom">
          <ArrowDownwardIcon style={{width: '50px', height: '50px' }} />
        </div>
      </div>

      <Container className="content-container-left">
        <Bounce left>
          <Row className="left">
            <Col lg={8}>
              <div>
                <h3 className="main-header">WER WIR SIND</h3>
                <h4 className="sub-header">EIN PAAR WORTE ZU UNS</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <p>
                TheFriendNextDoor wurde gegründet, um allen Menschen eine
                Möglichkeit zu geben, Nachbarn und Freunden in Zeiten von Corona
                unkompliziert zu helfen. Ohne große Anmeldeprozeduren kann jeder
                sofort Hilfe anbieten oder nach Hilfe jeder Art suchen.
              </p>
              <div className="divider" />
            </Col>
          </Row>
        </Bounce>
      </Container>
      <Container className="content-container-right">
        <Bounce right>
          <Row className="right">
            <Col lg={8}>
              <div>
                <h3 className="main-header">FUNKTIONSWEISE</h3>
                <h4 className="sub-header">LEG EINFACH LOS</h4>
              </div>
            </Col>
          </Row>
          <Row className="right">
            <Col lg={8}>
              <p>
                Durch hinzuziehen der Ortungstechnik deines Geräts, bist du in
                der Lage mit Menschen in der Nähe interagieren zu können. Wir
                zeigen dir wem du in deiner direkten Umgebung Helfen kannst.
                Wenn du unser Angebot von deinem Smartphone nutzt, ist die
                Genauigkeit so hoch, dass eine gezielte Suche nach
                Hilfebedürftigen - z.B. beim Spazierengehen oder auf dem Weg zum
                Einkaufen - möglich ist.
              </p>
              <div className="image-button-extra">
                <Button onClick={() => props.history.push('/signup')}>
                  Kostenlos Anmelden!
                </Button>
              </div>
              <div className="divider" />
            </Col>
          </Row>
        </Bounce>
      </Container>

      <Container className="content-container-left">
        <Bounce left>
          <Row className="left">
            <Col lg={8}>
              <div>
                <h3 className="main-header">WER KANN UNSEREN SERVICE NUTZEN</h3>
                <h4 className="sub-header">JEDER!</h4>
              </div>
            </Col>
          </Row>
          <Row className="left">
            <Col lg={8}>
              <p>
                Nicht nur als private Person hast du die Möglichkeit mit deiner
                Umgebung zu interagieren, auch Unternehmen und Organisationen
                jeder Art (z.B. Restaurants, Kirchen, Lebensmittelgeschäfte
                etc.) haben bei TheFriendNextDoor die Gelegenheit, gezielt
                Personen in ihrem Umkreis über einen Lieferservice und andere
                passende Dienste zu informieren.
                <br />
              </p>
              <div className="divider" />
            </Col>
          </Row>
        </Bounce>
      </Container>
      <Container className="content-container-right">
        <Bounce right>
          <Row className="right">
            <Col lg={8}>
              <div>
                <h3 className="main-header">KONTAKT</h3>
                <h4 className="sub-header">WIR SIND FÜR DICH DA</h4>
              </div>
            </Col>
          </Row>

          <Row className="right">
            <Col lg={8}>
              <p>
                Weitere Informationen zu TheFriendNextDoor findest du in unserem
                <br />
                <a href="/faq"> FAQ-Abschnitt</a>.
                <br />
                <br />
                Oder kontaktiere uns über unser
                <br />
                <a href="/contact">Kontaktformular</a>
              </p>
              <div className="divider" />
            </Col>
          </Row>
        </Bounce>
      </Container>
      {showInstallMessage && <Tooltip />}
      <Footer />
    </div>
  );
}

export default withRouter(Landingpage);
