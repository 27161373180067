import {
  APPLICATION_SET_LOCATION,
  APPLICATION_SET_TOKEN
} from '../action.collection';

const initialState = { token: '' };

export const application = (state = initialState, action) => {
  switch (action.type) {
    case APPLICATION_SET_LOCATION: {
      return { ...state, ...action.payload };
    }

    case APPLICATION_SET_TOKEN: {
      return { ...state, token: action.payload };
    }

    default:
      return state;
  }
};
