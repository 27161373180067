import React from 'react';
import { Card, Container, Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../../components/header/header';
import Bounce from 'react-reveal/Bounce';
import './privacies.scss';
import BackButton from '../../components/back-button/back-button';

function Privacies(props) {
  return (
    <div className="privacies">
      <Header />
      <BackButton />
      <Container>
        <Row>
          <Col>
            <Bounce left>
              <Card>
                <Card.Header>
                  <Card.Title>Nutzungsbedingungen</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <h3>
                      Pflichten als Nutzerin-/Nutzer von TheFriendNextDoor{' '}
                    </h3>
                    <ol>
                      <li>
                        Einer Ihrer Pflichten als Nutzer/Nutzerin ist es, dass
                        Sie keine Inhalte veröffentlichen, die gegen allgmeine
                        Forenregeln, gegen die guten Sitten oder sonst gegen
                        geltendes deutsches Recht verstoßen. Folgende Punkte
                        sind Ihnen nicht genehmigt:
                        <ul>
                          <li>
                            Inhalte zu veröffentlichen, die unwahr sind und
                            deren Veröffentlichung einen Straftatbestand oder
                            eine Ordnungswidrigkeit erfüllt,
                          </li>
                          <li>
                            Versendung von Spam über das Forum an andere
                            Forum-Nutzer/Form-Nutzerin,
                          </li>
                          <li>
                            Verwendung von gesetzlich durch Urheber- und
                            Markenrecht geschützte Inhalte ohne rechtmäßige
                            Berechtigung (z.B. Pressemitteilungen etc.),
                          </li>
                          <li>Handlungen, die wettbewerbswidrig sind,</li>
                          <li>
                            mehrfache Einstellung von Themen (sogenannte
                            Doppelpostings),
                          </li>
                          <li>
                            Inhalte zu veröffentlichen, die beleidigend,
                            rassistisch, diskriminierend oder pornographische
                            Elemente aufweisen gegenüber anderen
                            Nutzern/Nutzerinnen und der Allgemeinheit.
                          </li>
                        </ul>
                      </li>
                      <p>
                        Ihre Pflicht als TheFriendNextDoor-Nutzer/-Nutzerin ist
                        es, die Nutzungsbedingung, vor der Veröffentlichung
                        Ihres Beitrages im Forum zu beachten und zu überprüfen,
                        ob Sie sich an diese Punkte gehalten haben.
                      </p>
                      <li>
                        Sollten Sie gegen diese Nutzungsbedingung verstoßen,
                        behalten wir uns das Recht vor, gegen Sie folgende
                        Schritte vorzunehmen:
                        <ul>
                          <li>
                            Ihre eingestellten Beiträge zu löschen und diese
                            abzuändern,
                          </li>
                          <li>
                            Verbot weiterhin im Forum Beiträge zu verfassen und{' '}
                          </li>
                          <li>Sperrung des Zugangs als Nutzer/Nutzerin.</li>
                        </ul>
                      </li>
                      <li>
                        Haben Sie als TheFriendNextDoor-Nutzer/Forum-Nutzerin
                        nicht die Regeln beachtet und sind hierdurch mögliche
                        Rechtsverstöße entstanden, die durch Ihre eingestellten
                        Inhalte auf unserer Plattform entstanden sind
                        (Pflichtverletzung), verpflichten Sie sich als
                        Nutzer/Nutzerin, uns von jeglichen Ansprüchen, auch den
                        Schadenersatzansprüchen, freizustellen und diesen die
                        Kosten zu ersetzen.
                        <br />
                        Zudem ist der Nutzer/die Nutzerin verpflichtet uns, bei
                        Schadenersatzansprüchen hinsichtlich der Abwehr des
                        durch ihn entstandenen Rechtsverstoßes
                        (Pflichtverletzung s.o.), zu unterstützen und die Kosten
                        einer angemessenen Rechtsverteidigung für uns zu tragen.
                      </li>
                      <li>
                        Durch Ihr Einverständnis garantieren Sie uns, dass Sie
                        keine Beträge erstellen werden, die gegen die
                        Nutzungsbedingung verstoßen. Entsprechendes gilt auch
                        für das Setzen von externen Links und die Signaturen.
                      </li>
                    </ol>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Bounce>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(Privacies);
