import React, { Component } from 'react';
import MainPage from './views/main-page/main-page';
import Login from './views/login/login';
import { checkToken } from './utils/fetch';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import store from './redux/store';
import './App.scss';
import SignUp from './views/signup/signup';

import DetailPage from './views/detail-page/detail-page';
import Logout from './views/logout/logout';
import ContactForm from './views/contactForm/contactForm';
import Landingpage from './views/landingpage/landingpage';
import Impressum from './views/impressum/impressum';
import Privacies from './views/privacies/privacies';
import DataPrivacies from './views/data-privacies/data-privacies';
import AddJob from './views/addJob/addJob';
import Faq from './views/faq/faq';
import Message from './views/message/message';
import CacheBuster from './CacheBuster';
import GeneralInformations from './views/general-informations/general-informations';
import Informations from './views/informations/informations';
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  _loadData = async () => {
    const token = await localStorage.getItem('token');

    if (token) {
      try {
        await checkToken(token).then(data => {
          if (!data.username) {
            this.props.history.push('/');
          }
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  componentDidMount() {
    this._loadData();
  }

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }

          return (
            <div className="App">
              <Provider store={store}>
                <Router>
                  <Switch>
                    <PrivateRoute exact path="/" component={Landingpage} />
                    <PrivateRoute
                      exact
                      path="/details/:id"
                      component={DetailPage}
                    />

                    <Route exact path="/signup" component={SignUp} />
                    <Route exact path="/signin" component={Login} />
                    <Route exact path="/logout" component={Logout} />
                    <Route exact path="/contact" component={ContactForm} />
                    <Route exact path="/impressum" component={Impressum} />
                    <Route exact path="/privacies" component={Privacies} />
                    <Route
                      exact
                      path="/data-privacies"
                      component={DataPrivacies}
                    />
                    <Route exact path="/addnew" component={AddJob} />
                    <Route exact path="/faq" component={Faq} />
                    <Route exact path="/message" component={Message} />
                    <Route
                      exact
                      path="/informations"
                      component={Informations}
                    />
                    <Route
                      exact
                      path="/general-information-corona-covid19"
                      component={GeneralInformations}
                    />
                    <Redirect from="*" to="/" />
                  </Switch>
                </Router>
              </Provider>
            </div>
          );
        }}
      </CacheBuster>
    );
  }
}

export default App;
